import { IconButton, iconButtonClasses, styled, ToggleButton } from '@mui/material';
import { toggleButtonClasses } from '@mui/material/ToggleButton';
import React from 'react';

import { useElementActions } from 'components/RichText/Hooks/useElementActions';
import { SlateTextFormat, useFormatActions } from 'components/RichText/Hooks/useFormatActions';
import { ElementType } from 'components/RichText/CustomTypes';

const RichToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
  padding: '1px',
  border: 0,
  margin: '1px',
  fill: theme.palette.grey[800],

  [`&.${toggleButtonClasses.disabled}`]: {
    fill: theme.palette.action.disabled,
  },
}));

export const RichButton = styled(IconButton)(({ theme }) => ({
  padding: '1px',
  border: 0,
  margin: '1px',
  fill: theme.palette.grey[800],
  borderRadius: '4px',

  [`&.${iconButtonClasses.disabled}`]: {
    fill: theme.palette.action.disabled,
  },
}));

const RichToggleButton: React.FC<{
  value: any;
  children: React.ReactNode;
  isSelected: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
}> = (props) => {
  return (
    <RichToggleButtonStyled
      disableRipple
      value={props.value}
      selected={props.isSelected}
      onMouseDown={(e) => e.preventDefault()}
      onClick={props.handleClick}
    >
      {props.children}
    </RichToggleButtonStyled>
  );
};

export const RichFormatButton: React.FC<{
  format: SlateTextFormat;
  children: React.ReactNode;
}> = (props) => {
  const { isSelected, handleClick } = useFormatActions(props.format);

  // preventDefault onMouseDown is needed to keep selection in editor.
  return (
    <RichToggleButton value={props.format} isSelected={isSelected} handleClick={handleClick}>
      {props.children}
    </RichToggleButton>
  );
};

export const RichElementButton: React.FC<{
  format: ElementType;
  children: React.ReactNode;
}> = (props) => {
  const { isActive, handleClick } = useElementActions(props.format);

  // preventDefault onMouseDown is needed to keep selection in editor.
  return (
    <RichToggleButton value={props.format} handleClick={handleClick} isSelected={isActive}>
      {props.children}
    </RichToggleButton>
  );
};
