import React from 'react';
import { useCallback } from 'react';
import { RenderLeafProps } from 'slate-react';

import { CustomText } from '../CustomTypes';

export type CustomElement = {
  type: keyof CustomText;
  render: () => React.ReactNode | null;
};

export const useLeafRenderer = (customElements?: { type: keyof CustomText; render: () => React.ReactNode }[]) => {
  return useCallback(({ children, leaf, attributes }: RenderLeafProps) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }

    if (leaf.strikethrough) {
      children = <s>{children}</s>;
    }

    const customRenderer = customElements?.find((x) => leaf[x.type]);
    if (customRenderer) {
      children = (
        <>
          {children}
          {customRenderer.render()}
        </>
      );
    }

    return <span {...attributes}>{children}</span>;
  }, customElements ?? []);
};
