// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.richEditor_O52c5{border-radius:5px;background-color:#fff;display:flex;flex-direction:column;justify-content:stretch}.richEditorContent_ButxV{padding:0 !important;border-top-left-radius:0 !important;border-top-right-radius:0 !important;height:fit-content !important;background:rgba(0,0,0,0) !important}.richEditorInput_kbidk{margin:0;min-height:80px;position:relative;padding:8px 12px !important;height:fit-content !important;font-weight:300;line-height:24px}`, "",{"version":3,"sources":["webpack://./src/components/RichText/RichEditor/RichEditor.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,qBAAA,CACA,YAAA,CACA,qBAAA,CACA,uBAAA,CAGF,yBACE,oBAAA,CACA,mCAAA,CACA,oCAAA,CACA,6BAAA,CACA,mCAAA,CAGF,uBACE,QAAA,CACA,eAAA,CACA,iBAAA,CACA,2BAAA,CACA,6BAAA,CAEA,eAAA,CACA,gBAAA","sourcesContent":[".richEditor {\n  border-radius: 5px;\n  background-color: #ffffff;\n  display: flex;\n  flex-direction: column;\n  justify-content: stretch;\n}\n\n.richEditorContent {\n  padding: 0 !important;\n  border-top-left-radius: 0 !important;\n  border-top-right-radius: 0 !important;\n  height: fit-content !important;\n  background: transparent !important;\n}\n\n.richEditorInput {\n  margin: 0;\n  min-height: 80px;\n  position: relative;\n  padding: 8px 12px !important;\n  height: fit-content !important;\n\n  font-weight: 300;\n  line-height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"richEditor": `richEditor_O52c5`,
	"richEditorContent": `richEditorContent_ButxV`,
	"richEditorInput": `richEditorInput_kbidk`
};
export default ___CSS_LOADER_EXPORT___;
