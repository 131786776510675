import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { GenerationsInfoMap } from 'api/firmware-api';
import ZipFileIcon from 'assets/icons/zip-file.svg';
import { AppForm } from 'components/AppForm/AppForm';
import { AppHint } from 'components/AppHint/AppHint';
import { AppTable } from 'components/AppTable/AppTable';
import { FileDragAndDrop } from 'components/FileDragAndDrop/FileDragAndDrop';
import { getFormError, getIsFormErrored } from 'helpers/react-hook-form-helpers';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { useUploadFirmwareFilesForm } from './useUploadFirmwareFilesForm/useUploadFirmwareFilesForm';
import { useUploadFirmwareFilesTable } from './useUploadFirmwareFilesTable/useUploadFirmwareFilesTable';

import styles from './UploadFileForm.module.scss';

export type UploadFileFormProps = {
  onClose: () => void;
  setFormLocked?: (val: boolean) => void;
  onSubmitSuccess: () => void;
};

export const UploadFileForm: React.FC<UploadFileFormProps> = (props) => {
  const { onClose, onSubmitSuccess, setFormLocked } = props;
  const [uploadProgress, setFileUploadProgress] = useState<number>();
  const stages = useAvailableTargetsQuery()?.data?.stages?.map((x) => x.name) ?? [];

  const { files, formState, handleSubmit, clearErrors, sizeLimitMessage, actions, handleUpload, isBusy } =
    useUploadFirmwareFilesForm(onSubmitSuccess, setFileUploadProgress);
  const isFormErrored = getIsFormErrored(formState);

  useEffect(() => {
    if (setFormLocked) setFormLocked(formState.isSubmitting);
  }, [formState.isSubmitting]);

  const table = useUploadFirmwareFilesTable({ actions, files, stages });

  const actionButtons = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={files.length === 0 || isFormErrored}>
      Save
    </Button>,
  ];

  return (
    <AppForm
      title="Upload new FW"
      onSubmit={handleSubmit}
      actions={actionButtons}
      helperText={getErrorMessage(formState.errors.root?.message) || sizeLimitMessage}
      error={isFormErrored}
      className={styles.uploadFileForm}
      isLoading={isBusy}
      onChange={() => clearErrors()}
      uploadProgress={uploadProgress}
      classNames={{ infoPanel: styles.infoPanel }}
    >
      <FileDragAndDrop
        acceptedExtensions=".zip"
        icon={<ZipFileIcon />}
        handleFiles={handleUpload}
        error={getIsFormErrored(formState)}
        onUploadProgress={setFileUploadProgress}
        inputId="file-upload"
        multiple={true}
        dragAreaChildren={
          <div className={styles.fileDescriptionContainer}>
            <span>.zip containing firmware file and version.txt</span>
            <span>
              name of a firmware file must be of exact template
              <FirmwareFileNamesHint />
            </span>
          </div>
        }
      />
      {files.length > 0 && (
        <AppTable classNames={{ headCell: styles.tableHeadCell }} estimateRowSize={() => 40} table={table} />
      )}
    </AppForm>
  );
};

const getErrorMessage = (errors?: string) => errors && errors.split(';').map((error) => <span>{error}</span>);

const FirmwareFileNamesHint = () => (
  <AppHint>
    <div className={styles.firmwareFileNamesHint}>
      {GenerationsInfoMap.map((generation) => (
        <div key={generation.firmwareGeneration}>
          <span className={styles.firmwareFileName}>{generation.name}</span>
          <span>{generation.firmwareFileNameExample}</span>
        </div>
      ))}
    </div>
  </AppHint>
);
