export const ValidUrlRegex =
  // Valid example: https://loewensteinmedical.com/de-de/
  // Invalid example: www.google.com
  /https?:\/\/(www\.)?([-a-zA-Z0-9@:%_\+~#=]{2,63}\.){1,15}[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const isUrl = (url: string) => {
  return url.match(ValidUrlRegex);
};

export const MsgInvalidUrl = 'Link url is invalid. Valid example: https://loewensteinmedical.com/de-de/';
