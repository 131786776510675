import { Button } from '@mui/material';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { TenantTarget } from 'api/target-api';
import { AppForm } from 'components/AppForm/AppForm';
import { AppModal } from 'components/AppModal/AppModal';
import { AppTable } from 'components/AppTable/AppTable';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { TenantTargetModel, useTargetTenantColumns } from '../TenantColumns/TenantColumns';
import { useSelectedTenants } from './useSelectedTenants';

import styles from './TenantModal.module.scss';

export type TenantModalProps = {
  existingTenants: TenantTargetModel[];
  selectedStages: string[];
  selectedCountries: string[];
  open: boolean;
  onClose: () => void;
  onSave: (selectedTenants: TenantTargetModel[]) => void;
};

export const TenantModal: React.FC<TenantModalProps> = (props) => {
  const { existingTenants, onSave, open, selectedStages, selectedCountries, onClose } = props;

  const { addTenants, removeTenants, selectedTenants, displayedTenants, setTenantsSearch, tenantsSearch } =
    useSelectedTenants({
      existingTenants,
      selectedStages,
      selectedCountries,
    });

  const tenantColumns = useTargetTenantColumns({ isReadOnly: false, addTenants, removeTenants });
  const table = useReactTable<TenantTargetModel>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: getTenantKey,
    manualSorting: true,
    data: displayedTenants,
    columns: tenantColumns,
  });
  const tenantActions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="button" key={'save'} onClick={() => onSave(selectedTenants)}>
      Save
    </Button>,
  ];

  return (
    <AppModal open={open} onClose={onClose} contentClassName={styles.tenantFormContainer}>
      <AppForm
        classNames={{ content: styles.tenantFormContent }}
        title="Select target organizations"
        className={styles.tenantForm}
        actions={tenantActions}
      >
        <SearchInput
          requestDelay={10}
          value={tenantsSearch}
          onStop={(val) => {
            setTenantsSearch(val ?? '');
          }}
          placeholder="Search"
          data-testid="tenant-modal__searchbar"
        />
        <AppTable
          table={table}
          estimateRowSize={() => TenantModalRowEstimatedHeight}
          classNames={{ bodyCell: styles.bodyCell }}
        />
      </AppForm>
    </AppModal>
  );
};
export const TenantModalRowEstimatedHeight = 73;
export const getTenantKey = (tenant: TenantTarget) => `${tenant.stage}/${tenant.tenant}`;
