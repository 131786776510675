import React from 'react';
import styles from './OrganizationsHint.module.scss';

export type OrganizationsHintProps = { attribute: string };

export const OrganizationsHint: React.FC<OrganizationsHintProps> = (props) => {
  const { attribute } = props;

  return (
    <div className={styles.organizationsHint}>
      <span>Please note that {attribute} are only used to filter organizations:</span>
      <span>they will not be used to dispatch the notification.</span>
    </div>
  );
};
