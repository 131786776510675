import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type Option = {
  value: string;
  label: string;
  selected: boolean;
};

type Props = {
  options: Option[];
  onChange: (value: string, checked: boolean) => void;
};

export const CheckboxGroup: React.FC<Props> = ({ options, onChange }) => (
  <FormGroup>
    {options.map((option, index) => (
      <FormControlLabel
        key={index}
        checked={option.selected}
        onChange={(_, checked) => {
          onChange(option.value, checked);
        }}
        control={<Checkbox size="small" />}
        label={option.label}
        value={option.value}
      />
    ))}
  </FormGroup>
);
