import { useInfiniteQuery } from '@tanstack/react-query';

import { PageDto } from 'api/common';
import { NotificationDto, NotificationsApi, NotificationsQueryParameters } from 'api/notifications-api';
import { getNotificationsPageQueryKey } from './react-query/query-keys';
import { getNextPageParam } from './react-query/infinite-queries-utils';

export function useInfiniteNotificationsQuery(queryParams: Omit<NotificationsQueryParameters, 'pageNumber'>) {
  return useInfiniteQuery<PageDto<NotificationDto>>(
    getNotificationsPageQueryKey(queryParams),
    async ({ pageParam = 1 }) =>
      await NotificationsApi.getNotifications({
        ...queryParams,
        pageNumber: pageParam,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => getNextPageParam(lastPage, pages, queryParams),
    },
  );
}
