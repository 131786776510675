import React, { useCallback } from 'react';
import { RenderElementProps } from 'slate-react';
import { match } from 'ts-pattern';
import { LinkComponent } from '../Elements/LinkElement/LinkElement';

export const useElementRenderer = (readOnly: boolean) =>
  useCallback((renderProps: RenderElementProps) => {
    return match(renderProps.element.type)
      .with('p', () => <p {...renderProps.attributes}>{renderProps.children}</p>)
      .with('link', () => <LinkComponent {...(renderProps as any)} isReadOnly={readOnly} />)
      .with('ol', () => <ol {...renderProps.attributes}>{renderProps.children}</ol>)
      .with('ul', () => <ul {...renderProps.attributes}>{renderProps.children}</ul>)
      .with('li', () => <li {...renderProps.attributes}>{renderProps.children}</li>)
      .otherwise(() => renderProps.children);
  }, []);
