import { JSZipObject } from 'jszip';

export const getExtension = (name: string) => {
  const splittedName = name.split('.');
  return splittedName[splittedName.length - 1];
};

export const unzipFile = async (val: JSZipObject) =>
  new File([await val?.async('blob')], val.name.slice(val.name.lastIndexOf('/') + 1));

export const getFilesCumulativeSize = (files: File[]) => files.reduce((sum, file) => sum + file.size, 0);

export const bytesToMegaBytes = (mbCount: number): number => {
  return Math.round(mbCount / 1024 / 1024);
};

export const megaBytesToBytes = (bCount: number): number => {
  return bCount * 1024 * 1024;
};
