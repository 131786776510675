import { AuthData } from 'helpers/auth/auth-data';

const storage = window.localStorage;

export const localStorageKeys = {
  authData: 'authData',
};

export const getAuthDataLocalStorage = (): AuthData | null => {
  const data = storage.getItem(localStorageKeys.authData);
  return data ? JSON.parse(data) : null;
};
export const setAuthDataLocalStorage = (token: AuthData | null) =>
  storage.setItem(localStorageKeys.authData, JSON.stringify(token));
