import React, { useMemo } from 'react';

import { SortingType } from 'api/common';
import SortingDirection from 'assets/icons/sorting-direction.svg';

import styles from './SortableHeader.module.scss';

export type SortableHeaderProps = {
  sortingControl: SortingControl;
  content: React.ReactNode;
};

export const SortableHeader: React.FC<SortableHeaderProps> = ({ content, sortingControl }) => {
  const { onSortingChange, sortingType, isActive } = sortingControl;

  return (
    <span
      onClick={() => {
        onSortingChange(isActive ? getOppositeSortingType(sortingType) : sortingType);
      }}
      className={styles.sortableHeader}
    >
      {content}
      <span
        className={styles.sortingTypeIcon}
        data-rotate={sortingType !== SortingType.Descending}
        data-active={isActive}
      >
        {<SortingDirection />}
      </span>
    </span>
  );
};

export type SortingControl = {
  onSortingChange: (sorting: SortingType) => void;
  sortingType: SortingType;
  isActive: boolean;
};
export const useSortingControl = (
  sortingType: SortingType,
  onSortingChange: (sorting: SortingType) => void,
  isActive: boolean = true,
): SortingControl =>
  useMemo(
    () => ({
      onSortingChange,
      sortingType,
      isActive,
    }),
    [sortingType, onSortingChange, isActive],
  );

const getOppositeSortingType = (currentType: SortingType) =>
  currentType === SortingType.Ascending ? SortingType.Descending : SortingType.Ascending;
