import { Checkbox } from '@mui/material';
import { HeaderContext, Row } from '@tanstack/react-table';
import React, { FC } from 'react';

import { TargetTenantColumnsSettings, TenantTargetModel } from './TenantColumns';

export type MenuHeaderCellProps = {
  context: HeaderContext<TenantTargetModel, unknown>;
  settings: TargetTenantColumnsSettings;
};
export const MenuHeaderCell: FC<MenuHeaderCellProps> = ({ context, settings: { addTenants, removeTenants } }) => (
  <Checkbox
    sx={{
      padding: 0,
    }}
    checked={context.table.getRowModel().flatRows.every((x) => x.original.isSelected)}
    inputProps={{
      'data-testid': 'tenant-modal__table__header__checkbox',
    }}
    onChange={(e) => {
      const tenants = context.table.getRowModel().flatRows.map((x) => x.original);
      e.target.checked ? addTenants(tenants) : removeTenants(tenants);
    }}
    disabled={context.table.getRowModel().flatRows.length === 0}
  />
);

export type MenuBodyCellProps = {
  row: Row<TenantTargetModel>;
  settings: TargetTenantColumnsSettings;
};
export const MenuBodyCell: FC<MenuBodyCellProps> = ({ row, settings: { addTenants, removeTenants } }) => (
  <Checkbox
    sx={{
      padding: 0,
    }}
    inputProps={{
      'data-testid': 'tenant-modal__table__row__checkbox',
    }}
    checked={row.original.isSelected}
    onChange={(e) => (e.target.checked ? addTenants([row.original]) : removeTenants([row.original]))}
  />
);
