// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organizationsHint_a5vE9{display:flex;flex-direction:column;align-items:center;gap:4px}`, "",{"version":3,"sources":["webpack://./src/features/NotificationsPage/common/OrganizationsHint/OrganizationsHint.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".organizationsHint {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"organizationsHint": `organizationsHint_a5vE9`
};
export default ___CSS_LOADER_EXPORT___;
