import React, { ReactNode, useCallback, useState } from 'react';
import { Editor, Location, Range, Element as SlateElement, Transforms } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';

import { AppHint } from 'components/AppHint/AppHint';
import { AppModal } from 'components/AppModal/AppModal';
import { LinkElement } from 'components/RichText/CustomTypes';
import { isUrl } from 'helpers/urlUtils';
import { LinkEditor } from './LinkEditor';

import styles from './LinkElement.module.scss';

export type LinkElementProps = {
  attributes: any;
  element: LinkElement;
  children: ReactNode[];
  isReadOnly: boolean;
};

export const LinkComponent = ({ attributes, children, element, isReadOnly }: LinkElementProps) => {
  const isSelected = useSelected();
  const editor = useSlateStatic();

  const [isEditing, setIsEditing] = useState(!!element.isNew);
  const [isError, setIsError] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e.ctrlKey || isReadOnly) {
        try {
          e.preventDefault();
          window.open(element.url, '_blank');
        } catch (ex) {
          if (ex instanceof DOMException && !isUrl(element.url)) {
            if (!isReadOnly) setIsEditing(true);
            else setIsError(true);
          } else throw ex;
        }
      }
    },
    [isReadOnly, element.url],
  );

  return (
    <a
      {...attributes}
      href={element.url}
      data-selected={isSelected}
      rel="noreferrer"
      target="_blank"
      onClick={handleClick}
      onDoubleClick={() => setIsEditing(true)}
    >
      <AppHint
        component={children}
        control={{
          isOpen: isError,
          setIsOpen: setIsError,
          closeOnLeave: true,
        }}
        className={styles.errorHintContainer}
      >
        <span>
          Url <strong>{element.url}</strong> is not valid.
        </span>
      </AppHint>

      <AppModal
        open={isEditing && !isReadOnly}
        onClose={() => {
          editor.setNodes({ isNew: false }, { at: ReactEditor.findPath(editor, element) });
          setIsEditing(false);
        }}
        dialogPaperClassName={styles.linkEditorModal}
      >
        {<LinkEditor element={element} />}
      </AppModal>
    </a>
  );
};

export const wrapLink = (editor: Editor, url: string) => {
  if (!editor.selection) return;

  if (isLinkElementSelected(editor)) {
    unwrapLink(editor);
    return;
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url: url,
    children: isCollapsed ? [{ text: url }] : [],
    isNew: !isCollapsed,
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const unwrapLink = (editor: Editor, at?: Location) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    at: at,
  });
};

const isLinkElementSelected = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};
