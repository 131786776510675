import { Editor, Transforms, Element, Node } from 'slate';
import { CustomEditor } from '../CustomTypes';

export const withSingleLine = (editor: CustomEditor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (!Editor.isEditor(node) && Element.isElement(node) && editor.isBlock(node) && (path.at(-1) ?? 0) >= 1)
      Transforms.mergeNodes(editor, { at: path });

    if (!editor.hasPath(path)) return;
    normalizeNode([node, path]);
  };

  return editor;
};
