import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

import { AppHint } from '../AppHint/AppHint';

import styles from './Field.module.scss';

export type FieldProps = {
  title: string;
  children?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  hint?: ReactNode;
  fieldClassName?: string;
};

export const Field: FC<FieldProps> = (props) => {
  const { children, className, title, titleClassName, fieldClassName } = props;
  return (
    <div className={clsx(className, styles.container)} data-app-field={title}>
      <div className={styles.titleContainer}>
        <div className={clsx(styles.title, titleClassName)}>{title}</div>
        {props.hint && <AppHint>{props.hint}</AppHint>}
      </div>
      <div className={clsx(styles.field, fieldClassName)}>{children}</div>
    </div>
  );
};
