import { Descendant, Text } from 'slate';

export const getPlainText = (nodes: Descendant[]) => {
  let result = '';

  let elementNodes: Descendant[] = [];
  nodes.forEach((node) => {
    if (Text.isText(node)) result += node.text;
    else elementNodes = elementNodes.concat(node.children);
  });

  if (elementNodes.length > 0) result += getPlainText(elementNodes);
  return result;
};
