import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { FetchLoginResponse } from './auth-data';
import { Base64 } from 'js-base64';

interface AxiosAuthRefreshRequestConfig extends AxiosRequestConfig {
  skipAuthRefresh?: boolean;
}

type RefreshTokenRequestType = {
  refresh_token: string;
  grant_type: 'refresh_token';
};

const clientId = 'web_client';
const clientKey = '';

const backendUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

export const sendRefreshTokenRequest = (refreshToken: string): Promise<FetchLoginResponse> => {
  const requestBody: RefreshTokenRequestType = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };

  return fetchTokenEndpoint('/connect/token', requestBody);
};

export const fetchTokenEndpoint = async (
  urlPath: string,
  body: RefreshTokenRequestType,
): Promise<FetchLoginResponse> => {
  const response: AxiosResponse = await axios.create().post(`${backendUri}${urlPath}`, queryString.stringify(body), {
    headers: {
      Authorization: `Basic ${Base64.btoa(`${clientId}:${clientKey}`)}`,
    },
    skipAuthRefresh: true, // taken from https://github.com/Flyrell/axios-auth-refresh/
  } as AxiosAuthRefreshRequestConfig);

  return {
    ...response.data,
  } as FetchLoginResponse;
};
