import { AppDateTimePicker } from '../../../components/AppDateTimePicker/AppDateTimePicker';
import React from 'react';
import { SxProps, Theme } from '@mui/material';

type SizeType = 'long' | 'short';

type OwnProps = {
  value: Date;
  onChange: (date: Date) => void;
  size: SizeType;
};

const getStyles = (size: SizeType): SxProps<Theme> => ({
  '& .MuiInputBase-root': { padding: '0px', background: 'transparent !important' },
  '& .MuiInputAdornment-root': { padding: '0px', marginLeft: '0px', '& button': { padding: '0px' } },
  '& input':
    size === 'short'
      ? { padding: '0px', width: '110px', height: '22px' }
      : { padding: '0px', width: '250px', height: '22px' },
  '& fieldset': { border: 'none' },
});

export const ReleaseDateTimePicker: React.FC<OwnProps> = ({ onChange, value, size }) => {
  return <AppDateTimePicker type="dateOnly" sx={getStyles(size)} value={value} onChange={onChange} />;
};
