import { format } from 'date-fns';
import * as locales from 'date-fns/locale';

export const getDateLocale = () =>
  Object.values(locales).find(
    (x) => x.code && [navigator.language, navigator.language.substring(0, 2)].includes(x.code),
  ) ?? locales.enGB;

export const formatAsDateTime = (date: string) => format(new Date(date), `P\xa0HH:mm`, { locale: getDateLocale() });
export const formatAsDate = (date: string) => format(new Date(date), `P\xa0`, { locale: getDateLocale() });

export const MsgInvalidDate = 'Date is not valid';
