import { Descendant, Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { ElementType } from '../CustomTypes';
import { wrapLink } from '../Elements/LinkElement/LinkElement';

const ListTypes: ElementType[] = ['ul', 'ol'];

/**
 * Allows to check and apply tags (block in terms of Slate) like 'ul', 'ol' to the editable text.
 * @param type one of element type.
 */
export const useElementActions = (type: ElementType) => {
  const editor = useSlate();

  const isActive = () => isElementActive(editor, type);
  const handleClick = () => handleElementAction(editor, type);

  return { isActive: isActive(), handleClick };
};

export const handleElementAction = (editor: Editor, type: ElementType) => {
  // When user applies a block like a list we need to wrap all selected notes
  // with parent of list type and then change type of all wrapped objects to li
  // Example:
  // we have selection like: [{ type: 'p', children: [...]}, { type: 'p', children: [...]}]
  // after apply it will be
  // [{ type: 'ul', children: [
  //    { type: 'li', children: [...] },
  //    { type: 'li', children: [...] }.
  // ]

  const isActive = isElementActive(editor, type);
  if (type === 'link') return wrapLink(editor, 'https://');

  const isList = ListTypes.includes(type);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && ListTypes.includes(n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'p' : isList ? 'li' : type,
  };

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: type, children: [] as Descendant[] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isElementActive = (editor: Editor, type: ElementType) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
    }),
  );

  return !!match;
};
