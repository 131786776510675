import { useCallback, useMemo } from 'react';

export type useIsoCodesReturn = {
  getCountryName: (countryCode: string) => string;
  getLanguageName: (languageCode: string) => string;
};

export const useIsoCodes: (targetLanguage?: string) => useIsoCodesReturn = (targetLanguage: string = 'en') => {
  const countryName = useMemo(
    () =>
      new Intl.DisplayNames([targetLanguage], {
        type: 'region',
      }),
    [targetLanguage],
  );
  const getCountryName = useCallback((countryCode: string) => countryName.of(countryCode) ?? 'Unknown', [countryName]);

  const languageName = useMemo(
    () =>
      new Intl.DisplayNames([targetLanguage], {
        type: 'language',
      }),
    [targetLanguage],
  );
  const getLanguageName = useCallback(
    (languageCode: string) => languageName.of(languageCode) ?? 'Unknown',
    [languageName],
  );

  return { getCountryName, getLanguageName };
};
