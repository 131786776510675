import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { NotificationsApi, NotificationsQueryParameters } from 'api/notifications-api';
import { TargetApi } from 'api/target-api';
import { getAvailableTargetsQueryKey, getFirmwarePageQueryKey, getNotificationsPageQueryKey } from './query-keys';

export const useNotificationsPageQuery = (pageParams: NotificationsQueryParameters) =>
  useQuery(getNotificationsPageQueryKey(pageParams), ({ signal }) =>
    NotificationsApi.getNotifications(pageParams, {
      signal,
    }),
  );

export const useInvalidateNotificationsPageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries({ queryKey: getNotificationsPageQueryKey() }), [queryClient]);
};

export const useInvalidateFirmwarePageQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(() => queryClient.invalidateQueries({ queryKey: getFirmwarePageQueryKey() }), [queryClient]);
};

export const useNotificationsDisableMutation = (onSuccess?: () => void) =>
  useMutation((id: string) => NotificationsApi.disableNotification(id), {
    onSuccess,
  });

export const useNotificationsDeleteMutation = (onSuccess?: () => void) =>
  useMutation((id: string) => NotificationsApi.deleteNotification(id), {
    onSuccess,
  });

export const useNotificationsPublishMutation = (onSuccess?: () => void) =>
  useMutation((id: string) => NotificationsApi.publishNotification(id), {
    onSuccess,
  });

export const useNotificationsUnpublishMutation = (onSuccess?: () => void) =>
  useMutation((id: string) => NotificationsApi.unpublishNotification(id), {
    onSuccess,
  });

export const useAvailableTargetsQuery = () =>
  useQuery(getAvailableTargetsQueryKey(), () => TargetApi.GetAvailableTargets(), {
    staleTime: 1000 * 60 * 15, // 15 minutes.
  });
