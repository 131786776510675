import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { TenantTarget } from 'api/target-api';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { MenuBodyCell, MenuHeaderCell } from './TenantCells';

import styles from './TenantColumns.module.scss';

export type TenantTargetModel = TenantTarget & {
  isSelected: boolean;
};

export type TargetTenantColumnsSettings = {
  isReadOnly: false;
  addTenants: (tenants: TenantTarget[]) => void;
  removeTenants: (tenants: TenantTarget[]) => void;
};
export type ReadonlyTargetTenantColumnsSettings = {
  isReadOnly: true;
};

export const useTargetTenantColumns: (
  settings: TargetTenantColumnsSettings | ReadonlyTargetTenantColumnsSettings,
) => ColumnDef<TenantTargetModel>[] = (settings) => {
  const { getCountryName } = useIsoCodes();

  return useMemo(
    () => [...getColunms(settings, getCountryName)],
    settings.isReadOnly ? [] : [settings.addTenants, settings.removeTenants],
  );
};

const columnHelper = createColumnHelper<TenantTargetModel>();
function* getColunms(
  settings: TargetTenantColumnsSettings | ReadonlyTargetTenantColumnsSettings,
  getCountryName: (countryCode: string) => string,
): Generator<ColumnDef<TenantTargetModel>, void, unknown> {
  if (!settings.isReadOnly)
    yield columnHelper.display({
      id: 'menu',
      header: (context) => <MenuHeaderCell context={context} settings={settings} />,
      cell: ({ row }) => <MenuBodyCell row={row} settings={settings} />,
      size: 50,
      meta: {
        headCellProps: {
          className: styles.checkboxCell,
        },
        bodyCellProps: {
          className: styles.checkboxCell,
        },
      },
    });

  yield {
    id: 'name',
    accessorKey: 'name',
    cell: ({ row }) => <span className={styles.name}>{row.original.name}</span>,
    header: 'NAME',
    size: 220,
    meta: {
      headCellProps: {
        className: styles.nameCell,
      },
      bodyCellProps: {
        className: styles.nameCell,
      },
    },
  };

  yield {
    id: 'stage',
    accessorKey: 'stage',
    cell: ({ row }) => <span className={styles.stage}>{row.original.stage}</span>,
    header: 'STAGE',
    size: 100,
  };

  yield {
    id: 'country',
    accessorKey: 'country',
    cell: ({ row }) => <span className={styles.country}>{getCountryName(row.original.countryCode)}</span>,
    header: 'COUNTRY',
    size: 120,
  };
}
