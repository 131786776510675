import { Editor } from 'slate';

import { ElementType } from '../CustomTypes';
import { handleElementAction } from './useElementActions';
import { handleFormatAction, SlateTextFormat } from './useFormatActions';

export const useHotKeys = (editor: Editor) => {
  return (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.ctrlKey || e.metaKey) {
      const hotKey = HotKeyMap.get(e.code);
      if (hotKey) {
        if (hotKey.isFormat) handleFormatAction(editor, hotKey.type);
        else handleElementAction(editor, hotKey.type as ElementType);

        e.preventDefault();
      }
    }
  };
};

type HotKeyMapping = { isFormat: true; type: SlateTextFormat } | { isFormat: false; type: ElementType };
const HotKeyMap = new Map<string, HotKeyMapping>([
  ['KeyK', { isFormat: false, type: 'link' }],
  ['KeyB', { isFormat: true, type: 'bold' }],
  ['KeyI', { isFormat: true, type: 'italic' }],
  ['KeyD', { isFormat: true, type: 'strikethrough' }],
]);
