import { PageDto, QueryParameters } from '../../api/common';

export function getNextPageParam<TItem>(
  lastPage: PageDto<TItem>,
  pages: PageDto<TItem>[],
  queryParams: QueryParameters,
) {
  const totalItems = lastPage.pageInfo.totalItems;
  const maxPage = Math.ceil(totalItems / queryParams.pageSize);
  return pages.length < maxPage ? pages.length + 1 : undefined;
}
