import { useCallback, useEffect, useState } from 'react';

import { TenantTarget } from 'api/target-api';
import { TenantTargetModel } from '../TenantColumns/TenantColumns';

export type useSelectedTenantsProps = {
  existingTenants: TenantTargetModel[];
  selectedStages: string[];
  selectedCountries: string[];
};

export const tenantsEqual = (left: TenantTargetModel | TenantTarget, right: TenantTargetModel | TenantTarget) =>
  left.tenant === right.tenant && left.stage === right.stage;

export const getTenantsAvailableForSelection = (
  tenants: TenantTargetModel[],
  selectedStages: string[],
  selectedCountries: string[],
) => {
  return tenants.filter(
    (x) =>
      (selectedCountries.length === 0 || selectedCountries.includes(x.countryCode)) &&
      (selectedStages.length === 0 || selectedStages.includes(x.stage)),
  );
};

export const filterTenantsNames = (tenants: TenantTargetModel[], search: string) =>
  tenants.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));

export const useSelectedTenants: ({ existingTenants, selectedCountries, selectedStages }: useSelectedTenantsProps) => {
  selectedTenants: TenantTargetModel[];
  displayedTenants: TenantTargetModel[];
  setSelectedTenants: React.Dispatch<React.SetStateAction<TenantTargetModel[]>>;
  addTenants: (tenants: TenantTarget[]) => void;
  removeTenants: (tenants: TenantTarget[]) => void;
  tenantsSearch: string;
  setTenantsSearch: React.Dispatch<React.SetStateAction<string>>;
} = ({ existingTenants, selectedStages, selectedCountries }) => {
  const [selectedTenants, setSelectedTenants] = useState<TenantTargetModel[]>(existingTenants);
  const [displayedTenants, setDisplayedTenants] = useState<TenantTargetModel[]>(selectedTenants);
  const [tenantsSearch, setTenantsSearch] = useState<string>('');

  const searchTenants = useCallback(
    (tenants: TenantTargetModel[]) => filterTenantsNames(tenants, tenantsSearch),
    [tenantsSearch],
  );

  const filterAvailableForSelection = useCallback(
    (tenants: TenantTargetModel[]) => getTenantsAvailableForSelection(tenants, selectedStages, selectedCountries),
    [selectedStages, selectedCountries],
  );

  useEffect(() => {
    setSelectedTenants(filterAvailableForSelection(existingTenants));
  }, [existingTenants, filterAvailableForSelection]);

  useEffect(() => {
    setDisplayedTenants(searchTenants(selectedTenants));
  }, [selectedTenants, tenantsSearch]);

  const updateTenantsSelection = useCallback(
    (tenants: TenantTarget[], newValue: boolean) =>
      selectedTenants.map((x) =>
        tenants.length === 0 || tenants.some((y) => tenantsEqual(x, y)) ? { ...x, isSelected: newValue } : x,
      ),
    [selectedTenants, tenantsEqual],
  );
  const addTenants = useCallback(
    (tenants: TenantTarget[]) => setSelectedTenants(updateTenantsSelection(tenants, true)),
    [updateTenantsSelection],
  );
  const removeTenants = useCallback(
    (tenants: TenantTarget[]) => setSelectedTenants(updateTenantsSelection(tenants, false)),
    [updateTenantsSelection],
  );

  return {
    addTenants,
    removeTenants,
    selectedTenants,
    setSelectedTenants,
    tenantsSearch,
    setTenantsSearch,
    displayedTenants,
  };
};
