import { useMemo, useRef, useState } from 'react';

import { FirmwareApi, UploadFirmwareFileDto } from 'api/firmware-api';
import { getProgressEventHandler, ProgressHandler } from 'helpers/axiosUtils';
import { bytesToMegaBytes, getFilesCumulativeSize, megaBytesToBytes } from 'helpers/fileUtils';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { transformProcessedFiles, useFirmwareFilesProcessor } from './useFirmwareFilesProcessor';

const MaxRequestSizeMB = 512;
const MaxSize = megaBytesToBytes(MaxRequestSizeMB);

export type UploadFirmwareFiles = { files: UploadFirmwareFileDto[] };

export type FirmwareFileActions = {
  removeFile: (index: number) => void;
  setFileSelectedStages: (index: number, stages: string[]) => void;
  setFileReleaseDate: (index: number, date: Date) => void;
};
export type FirmwareFilesSelector = (items: UploadFirmwareFileDto[]) => UploadFirmwareFileDto[];

export const useUploadFirmwareFilesForm = (onSubmitSuccess: () => void, handleProgress: ProgressHandler) => {
  const now = useRef<Date>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const form = useApplicationForm<UploadFirmwareFiles>({
    mode: 'all',
    values: {
      files: [],
    },
  });
  const { handleSubmit, setValue, setError, formState, watch, getValues, register } = form;

  register('files', {
    validate: (files) => getFirmwareFilesCumulativeSize(files) <= MaxSize || 'Files total size cannot exceed 512MB.',
  });

  const updateFiles = (selector: FirmwareFilesSelector) =>
    setValue('files', selector(getValues('files')), { shouldValidate: true });

  const processArchives = useFirmwareFilesProcessor();
  const handleUpload = async (fileList: FileList) => {
    setIsLoading(true);

    const { errors, values } = await processArchives([...fileList]);
    updateFiles((files) => [...files, ...transformProcessedFiles(values, now.current)]);

    if (errors.length)
      setError('root', {
        message: errors.join(';'),
      });

    setIsLoading(false);
  };

  const onSubmit = async ({ files }: UploadFirmwareFiles) => {
    await FirmwareApi.upload(files, {
      onUploadProgress: getProgressEventHandler(handleProgress),
    });
    onSubmitSuccess();
  };

  const actions: FirmwareFileActions = {
    removeFile: (i: number) => updateFiles((files) => files.toSpliced(i, 1)),
    setFileSelectedStages: (i: number, stages: string[]) =>
      updateFiles((files) => files.toSpliced(i, 1, { ...files[i], stages })),
    setFileReleaseDate: (i: number, releaseDate: Date) =>
      updateFiles((files) => files.toSpliced(i, 1, { ...files[i], releaseDate })),
  };

  return {
    ...form,
    formState,
    handleUpload,
    actions,
    handleSubmit: handleSubmit(onSubmit),
    files: useMemo(() => watch().files, [watch().files]),
    sizeLimitMessage: getSizeLimitMessage(getValues('files')),
    isBusy: isLoading || formState.isSubmitting,
  };
};

const getFirmwareFilesCumulativeSize = (files: UploadFirmwareFileDto[]) =>
  getFilesCumulativeSize(files.map((x) => x.file));

const getSizeLimitMessage = (files: UploadFirmwareFileDto[]) => {
  if (files.length === 0) return null;

  const size = getFirmwareFilesCumulativeSize(files);
  return `Uploaded ${bytesToMegaBytes(size)}/${bytesToMegaBytes(MaxSize)} MB.`;
};
