import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageDto, QueryParameters, append } from './common';
import * as url from 'url';

export enum DeviceGeneration {
  P2_8 = 'P2_8',
  P3 = 'P3',
  P3_4 = 'P3_4',
  P5 = 'P5',
}

export enum P28Subtype {
  Classic = 'Classic',
  Npsb = 'Npsb',
  Wireless = 'Wireless',
}

export enum DeviceFirmwareGeneration {
  P2_8 = 'P2_8',
  P2_8NBSP = 'P2_8NBSP',
  P2_8W = 'P2_8W',
  P3 = 'P3',
  P3_X = 'P3_X',
  P5 = 'P5',
}

export const FirmwareGenerations = Object.keys(DeviceFirmwareGeneration) as unknown as DeviceFirmwareGeneration[];
export type FullDeviceGeneration = {
  deviceGeneration: DeviceGeneration;
  p28Subtype?: P28Subtype;
};
export type GenerationInfo = FullDeviceGeneration & {
  name: string;
  firmwareGeneration: DeviceFirmwareGeneration;
  firmwareFileName: RegExp;
  firmwareFileNameExample: string;
};

export type FirmwareFileDto = {
  id: string;
  fileName: string;
  firmwareGeneration: DeviceFirmwareGeneration;
  stages: string[];
  version: string;
  releaseDate: string;
  uploadedAt: string;
  uploadedBy: string;
};

export type UploadFirmwareFileDto = {
  file: File;
  firmwareGeneration: DeviceFirmwareGeneration;
  stages: string[];
  version: string;
  releaseDate: Date;
};

export type UpdateFirmwareFileDto = {
  id: string;
  stages: string[];
  releaseDate: Date;
};

export const FirmwareSortingAttributes = ['version', 'uploadedAt'] as const;
export type FirmwareSortingAttribute = (typeof FirmwareSortingAttributes)[number];
export type FirmwareQueryParameters = QueryParameters & {
  stages?: string[];
  generations?: string[];
  sortingAttribute: FirmwareSortingAttribute;
};

export const FirmwareApi = {
  async get(requestParams: FirmwareQueryParameters, options?: AxiosRequestConfig): Promise<PageDto<FirmwareFileDto>> {
    const params = url.format({ query: requestParams });
    const result: AxiosResponse<PageDto<FirmwareFileDto>> = await axios.get(
      `api/firmware/files/page${params}`,
      options,
    );

    return result.data;
  },

  generateGettingFileUlr(id: string, downloadToken: string) {
    const params = url.format({ query: { downloadToken } });
    return `api/firmware/files/raw-file/${id}/${params}`;
  },

  async upload(files: UploadFirmwareFileDto[], options?: AxiosRequestConfig): Promise<void> {
    const data = new FormData();
    append(data, 'files', files);

    await axios.post(`api/firmware/files/upload`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...options,
    });
  },

  async update(file: UpdateFirmwareFileDto, options?: AxiosRequestConfig): Promise<void> {
    await axios.post(`api/firmware/files/update/${file.id}`, file, options);
  },

  async delete(fileId: string, options?: AxiosRequestConfig): Promise<void> {
    await axios.delete(`api/firmware/files/${fileId}`, options);
  },
};

export const getFullGenerationInfo = (file: FullDeviceGeneration) => {
  const info = GenerationsInfoMap.find(
    (x) => x.deviceGeneration === file.deviceGeneration && x.p28Subtype === file.p28Subtype,
  );
  if (!info) throw Error(`Invalid generation: ${file.deviceGeneration} and ${file.p28Subtype}`);
  return info;
};

export const getFirmwareGenerationInfo = (generation: DeviceFirmwareGeneration) => {
  const info = GenerationsInfoMap.find((x) => x.firmwareGeneration === generation);
  return info!;
};

export const GenerationsInfoMap: GenerationInfo[] = [
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8,
    name: 'P2.8',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Classic,
    firmwareFileName: /.*Update28.bin/,
    firmwareFileNameExample: 'Update28.bin',
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8NBSP,
    name: 'P2.8NPSB',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Npsb,
    firmwareFileName: /.*Update35196.bin/,
    firmwareFileNameExample: 'Update35196.bin',
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P2_8W,
    name: 'P2.8W',
    deviceGeneration: DeviceGeneration.P2_8,
    p28Subtype: P28Subtype.Wireless,
    firmwareFileName: /.*Update35298.bin/,
    firmwareFileNameExample: 'Update35298.bin',
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P3,
    name: 'P3',
    deviceGeneration: DeviceGeneration.P3,
    firmwareFileName: /.*Update_weinmann_respiratory_device.zip/,
    firmwareFileNameExample: 'Update_weinmann_respiratory_device.zip',
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P3_X,
    name: 'pVENT (P3.X)',
    deviceGeneration: DeviceGeneration.P3_4,
    firmwareFileName: /.*Update_weinmann_respiratory_device_P3_2.zip/,
    firmwareFileNameExample: 'Update_weinmann_respiratory_device_P3_2.zip',
  },
  {
    firmwareGeneration: DeviceFirmwareGeneration.P5,
    name: 'P5',
    deviceGeneration: DeviceGeneration.P5,
    firmwareFileName: /.*LMT35131-v((?:\d+\.)*\d+)\.hex/,
    firmwareFileNameExample: 'LMT35131-v<VERSION>.hex',
  },
];
