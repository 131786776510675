import { TextField } from '@mui/material';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ReactEditor, useSlateStatic } from 'slate-react';

import CrossIcon from 'assets/icons/cross.svg';
import EditIcon from 'assets/icons/edit.svg';
import TickIcon from 'assets/icons/tick.svg';
import TrashIcon from 'assets/icons/trashbin.svg';
import { LinkElement } from 'components/RichText/CustomTypes';
import { MsgInvalidUrl, ValidUrlRegex } from 'helpers/urlUtils';
import { unwrapLink } from './LinkElement';

import styles from './LinkElement.module.scss';

export type LinkEditorProps = {
  element: LinkElement;
};

export const LinkEditor: React.FC<LinkEditorProps> = (props) => {
  const { element } = props;
  const [isEditing, setIsEditing] = useState(true);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const linkForm = useForm<{ url: string }>({ defaultValues: { url: element.url }, mode: 'onBlur' });
  const { formState, control, handleSubmit } = linkForm;
  const url = linkForm.register('url', {
    required: {
      value: true,
      message: 'Link url is required',
    },
    pattern: {
      value: ValidUrlRegex,
      message: MsgInvalidUrl,
    },
  });

  const onSubmit: SubmitHandler<{ url: string }> = ({ url }) => {
    editor.setNodes({ url }, { at: path });
    setIsEditing(false);
  };

  const handleInputInteraction = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(onSubmit)();
    } else if (event.key === 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      setIsEditing(false);
    }
  };

  const editingActions = (
    <>
      <ActionButton
        tabIndex={0}
        title="Cancel edit"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          setIsEditing(false);
        }}
        children={<CrossIcon />}
      />
      <ActionButton
        tabIndex={0}
        title="Save edit"
        disabled={!!formState.errors.url}
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          handleSubmit(onSubmit)();
        }}
        children={<TickIcon />}
      />
    </>
  );

  const viewingActions = (
    <>
      <ActionButton
        tabIndex={0}
        title="Edit link"
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          setIsEditing(true);
        }}
        children={<EditIcon />}
      />
      <ActionButton
        title="Delete link"
        tabIndex={0}
        onMouseDown={(event) => event.preventDefault()}
        onClick={() => {
          unwrapLink(editor, path);
        }}
        children={<TrashIcon />}
      />
    </>
  );

  return (
    <>
      <div className={styles.linkEditor}>
        {isEditing ? (
          <>
            <form className={styles.linkEditorInputContainer}>
              <Controller
                name={url.name}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={fieldState.isDirty ? !!fieldState.error : false}
                    helperText={fieldState.isDirty ? fieldState.error?.message : null}
                    autoFocus={true}
                    fullWidth={true}
                    inputRef={(el) => (el as HTMLInputElement)?.focus()}
                    onKeyDown={(event) => {
                      handleInputInteraction(event);
                    }}
                  />
                )}
              />
            </form>
            <div className={styles.actionsContainer}>{editingActions}</div>
          </>
        ) : (
          <>
            <div className={styles.linkEditorInputContainer}>
              <TextField
                fullWidth={true}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    event.stopPropagation();
                    event.preventDefault();
                  }
                }}
                disabled={true}
                value={element.url}
              />
            </div>
            <div className={styles.actionsContainer}>{viewingActions}</div>
          </>
        )}
      </div>
    </>
  );
};

const ActionButton: React.FC<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (
  props,
) => <button className={styles.actionButton} type="button" {...props}></button>;
