import { slateToHtml } from '@slate-serializers/html';
import React, { useRef } from 'react';

import { NotificationCreateDto, NotificationsApi } from 'api/notifications-api';
import { getRichTextContent } from 'components/RichText/RichEditor/RichEditor';
import { availableRoles } from 'constants/UserRoleTypes/utils';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { TenantTargetModel } from '../../common/TenantColumns/TenantColumns';
import { NotificationModel } from '../../common/utils';
import { BaseNotificationForm } from '../BaseNotificationForm/BaseNotificationForm';

export type CreateNotificationFormProps = {
  onSubmitSuccessful: () => void;
  onClose: () => void;
  setIsFormSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
};

type NotificationCreateModel = NotificationModel<NotificationCreateDto>;

export const CreateNotificationForm: React.FC<CreateNotificationFormProps> = (props) => {
  const { onSubmitSuccessful, onClose, setIsFormSubmitting } = props;
  const now = useRef<Date>(new Date());

  const form = useApplicationForm<NotificationCreateModel>({
    values: {
      richText: getRichTextContent(),
      plainText: '',
      startDate: now.current,
      endDate: null,
      cultures: null,
      stages: [],
      countries: [],
      tenants: [],
      targetType: 'All',
      roles: availableRoles.reduce((prev: any, curr: any) => prev | curr),
      selectedRoles: availableRoles,
    },
    mode: 'all',
  });

  const onSubmit = async (data: NotificationCreateModel, tenantsAvailableForSelection: TenantTargetModel[]) => {
    const { targetType, selectedRoles, ...submitData } = data;

    let tenants = null;
    if (targetType !== 'All')
      tenants = submitData.tenants?.length === 0 ? tenantsAvailableForSelection : submitData.tenants;

    let cultures = null;
    if (targetType !== 'All' && submitData.cultures !== null && submitData.cultures.length !== 0)
      cultures = submitData.cultures;

    let roles = availableRoles.reduce((prev: any, curr: any) => prev | curr);
    if (targetType !== 'All' && selectedRoles.length > 0)
      roles = selectedRoles.reduce((prev: any, curr: any) => prev | curr);

    await NotificationsApi.createNotification({
      ...submitData,
      cultures: cultures,
      tenants: tenants,
      roles: roles,
      richText: slateToHtml(submitData.richText),
    });
    onSubmitSuccessful();
  };

  return (
    <BaseNotificationForm
      form={form}
      onClose={onClose}
      title="New notification"
      setIsFormSubmitting={setIsFormSubmitting}
      onSubmit={onSubmit}
    />
  );
};
