import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';

import { getDateLocale } from 'helpers/dateUtils';
import Logo from 'assets/icons/logo.svg';
import { AlertProvider } from 'components/CustomAlert';
import { FirmwarePage } from 'features/FirmwarePage/FirmwarePage/FirmwarePage';
import { NotificationsPage } from 'features/NotificationsPage/NotificationsPage/NotificationsPage';
import { useIsAuthorized } from 'helpers/auth/auth-interceptor';
import { Links } from './navigation/routes';
import { redirectToLoginPage } from './openid/openid-manager';
import { theme } from './theme';

import styles from './App.module.scss';

export function App() {
  const isAuth = useIsAuthorized();

  useEffect(() => {
    if (!isAuth) {
      redirectToLoginPage();
    }
  }, []);

  return (
    <AlertProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale()}>
          <div className={styles.container}>
            <header className={styles.header}>
              <div className={styles.topRow}>
                <div className={styles.topRowContent}>
                  <Logo />
                </div>
              </div>
              <div className={styles.bottomRow}>
                <div className={styles.bottomRowContent}>
                  <HeaderLink to={Links.Authorized.Notifications.link()} title={'NOTIFICATIONS'} />
                  <HeaderLink to={Links.Authorized.Firmware.link()} title={'FIRMWARE'} />
                </div>
              </div>
            </header>
            <div className={styles.content}>
              <Routes>
                <Route path={Links.Authorized.Notifications.route} element={<NotificationsPage />} />
                <Route path={Links.Authorized.Firmware.route} element={<FirmwarePage />} />
                <Route path={'/*'} element={<Navigate to={Links.Authorized.Notifications.route} />} />
              </Routes>
            </div>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </AlertProvider>
  );
}

const HeaderLink: React.FC<{ title: string; to: string; testId?: string }> = (props) => {
  const { title, to, testId } = props;

  return (
    <NavLink
      className={({ isActive }) => clsx(styles.headerItem, isActive && styles.headerItemActive)}
      to={to}
      data-testid={testId}
    >
      {title}
    </NavLink>
  );
};
