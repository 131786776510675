import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';

import { FirmwareApi, UpdateFirmwareFileDto } from 'api/firmware-api';
import { AppDateTimePicker } from 'components/AppDateTimePicker/AppDateTimePicker';
import { AppForm } from 'components/AppForm/AppForm';
import { AppModal } from 'components/AppModal/AppModal';
import { Field } from 'components/Field/Field';
import { isValid } from 'date-fns';
import { StageOption, StagePicker } from 'features/FirmwarePage/StagePicker/StagePicker';
import { MsgInvalidDate } from 'helpers/dateUtils';
import { getIsFormErrored } from 'helpers/react-hook-form-helpers';
import { useInvalidateFirmwarePageQuery } from 'helpers/react-query/firmware-query-hooks';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { SelectedFirmwareFile, SelectedFirmwareFileContext } from '../FirmwarePage/FirmwarePage';

export const EditFirmwareModal: React.FC = () => {
  const { selectedFile, setSelectedFile } = useContext(SelectedFirmwareFileContext);

  return selectedFile ? <Modal selectedFile={selectedFile} setSelectedFile={setSelectedFile} /> : null;
};

const Modal: React.FC<{
  selectedFile: SelectedFirmwareFile;
  setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFirmwareFile | null>>;
}> = (props) => {
  const { selectedFile, setSelectedFile } = props;
  const { file } = selectedFile;
  const onClose = () => setSelectedFile(null);

  const invalidate = useInvalidateFirmwarePageQuery();
  const updateFileMutation = useMutation((data: UpdateFirmwareFileDto) => FirmwareApi.update(data), {
    onSuccess: () => {
      invalidate();
      onClose();
    },
  });
  const { data: targetData, isLoading: areTargetsLoading } = useAvailableTargetsQuery();
  const stages = targetData?.stages?.map((x) => x.name) ?? [];
  const form = useApplicationForm<UpdateFirmwareFileDto>({
    mode: 'all',
    defaultValues: {
      id: file.id,
      stages: file.stages,
      releaseDate: new Date(file.releaseDate),
    },
  });
  const formWatch = form.watch();
  const { formState, register } = form;
  const isFormErrored = getIsFormErrored(formState);

  const onSubmit = async (data: UpdateFirmwareFileDto) => {
    await updateFileMutation.mutateAsync(data);
  };

  const stageOptions: StageOption[] = stages.map((x) => ({
    name: x,
    isSelected: formWatch.stages.includes(x),
  }));

  const releaseDateField = register('releaseDate', {
    validate: (val) => (isValid(val) ? true : MsgInvalidDate),
  });

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={getIsFormErrored(form.formState)}>
      Save
    </Button>,
  ];

  return (
    <AppModal
      open={selectedFile?.selectionType === 'editing'}
      onClose={onClose}
      preventClose={updateFileMutation.isLoading}
    >
      <AppForm
        title="Edit firmware"
        onSubmit={form.handleSubmit(onSubmit)}
        isLoading={updateFileMutation.isLoading || areTargetsLoading}
        actions={actions}
        helperText={formState.errors.root?.message}
        error={isFormErrored}
        onChange={() => form.clearErrors()}
      >
        <Field title={'Stages'}>
          <StagePicker stages={stageOptions} onChange={(newStages) => form.setValue('stages', newStages)} />
        </Field>
        <Field title={'Release date'}>
          <Controller
            name={releaseDateField.name}
            control={form.control}
            render={({ field }) => (
              <AppDateTimePicker
                type="dateOnly"
                error={!!formState.errors.releaseDate}
                helperText={formState.errors.releaseDate?.message as any}
                {...field}
              />
            )}
          />
        </Field>
      </AppForm>
    </AppModal>
  );
};
