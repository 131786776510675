import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext, useState } from 'react';
import CloseIcon from '/src/assets/icons/close-cross.svg';

export type AlertContextProps = {
  setAlertMessage: (message: string) => void;
  setAlertState: (isOpen: boolean) => void;
};

export type AlertProviderProps = {
  children: React.ReactNode;
};

export const AlertContext = createContext<AlertContextProps>({
  setAlertMessage: () => undefined,
  setAlertState: () => undefined,
});

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [isAlertShown, setIsAlertShown] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const handleClose = () => {
    setIsAlertShown(false);
  };

  const contextProps: AlertContextProps = {
    setAlertMessage: setMessage,
    setAlertState: setIsAlertShown,
  };

  const snackBarActions = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon style={{ height: '18px' }} />
    </IconButton>
  );

  return (
    <AlertContext.Provider value={contextProps}>
      {children}
      <Snackbar
        ContentProps={{ style: { minWidth: 'auto', backgroundColor: '#525252CC' } }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000}
        open={isAlertShown}
        onClose={handleClose}
        message={message}
        key={'top' + 'center'}
        action={snackBarActions}
      />
    </AlertContext.Provider>
  );
};
