export type ShowBusyIndicatorInfo = { lockUI?: boolean; className?: string };

export const updateShowBusyIndicator = (
  state: ShowBusyIndicatorInfo[] | false,
  actions: { action: 'show' | 'hide'; info: ShowBusyIndicatorInfo }[],
) => {
  let newState = state;
  actions.forEach((x) => {
    if (x.action == 'show') {
      if (newState) {
        newState = [...newState, x.info];
      } else {
        newState = [x.info];
      }
    } else {
      if (newState && newState.length > 1) {
        newState = newState.filter((s) => s.className != x.info.className || s.lockUI != x.info.lockUI);
      } else {
        newState = false;
      }
    }
  });
  return newState;
};
