import Axios, { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { getAuthDataLocalStorage, localStorageKeys, setAuthDataLocalStorage } from '../../util/localStorageAccessor';
import { AuthData, FetchLoginResponse } from './auth-data';
import { useEffect, useState } from 'react';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import SuperTokensLock from 'browser-tabs-lock';
import { signOutRedirect } from '../../openid/openid-manager';
import { backendUri } from '../../openid/openid-settings';

const _authFunctions = new Set<(auth: AuthData | null) => void>();

/*
 * Used to prevent refreshing tokens from two tabs at the same time.
 * One tab has to wait for token to be refreshed.
 */
const refreshTokenLock = new SuperTokensLock();
const lockKey = 'refresh_token_lock';
const lockAcquiringTimeout = 10000;

export function setAuthDataState(data: AuthData | null) {
  _authFunctions.forEach((func) => {
    func(data);
  });
  setAuthDataLocalStorage(data);
}

export async function logOut() {
  await signOutRedirect();
}

export function postServerLogOut() {
  setAuthDataState(null);
  window.history.pushState(null, '', backendUri);
}

export function useIsAuthorized() {
  return useAuth() !== null;
}

export function useAuth() {
  const [auth, setAuth] = useState<AuthData | null>(getAuthDataLocalStorage());
  useEffect(() => {
    _authFunctions.add(setAuth);
    return () => {
      _authFunctions.delete(setAuth);
    };
  }, []);
  return auth;
}

export function setupAuthInterceptor(
  axios: AxiosInstance,
  refreshAuthCall: (authData: AuthData) => Promise<FetchLoginResponse>,
) {
  window.addEventListener('storage', (e) => {
    if (e.storageArea === localStorage && e.key === localStorageKeys.authData) {
      const authData = e.newValue ? JSON.parse(e.newValue) : null;
      setAuthDataState(authData);
    }
  });

  createAuthRefreshInterceptor(axios, async (error) => refreshAccessToken(refreshAuthCall, error));

  axios.interceptors.request.use(injectAccessTokenInterceptor);
}

async function refreshAccessToken(
  refreshAuthCall: (authData: AuthData) => Promise<FetchLoginResponse>,
  error: any,
): Promise<void> {
  const storedAuthData = getAuthDataLocalStorage();
  if (!storedAuthData) {
    throw error;
  }
  const oldAuthData = storedAuthData;
  const isAcquired = await refreshTokenLock.acquireLock(lockKey, lockAcquiringTimeout);
  if (!isAcquired) {
    return;
  }

  try {
    if (oldAuthData !== storedAuthData) {
      return;
    }

    const authData = await refreshAuthCall(storedAuthData);
    setAuthDataState(authData);
  } catch (e: any) {
    if (Axios.isAxiosError(e)) {
      logOut();
    }
    throw e;
  } finally {
    await refreshTokenLock.releaseLock(lockKey);
  }
}

export async function injectAccessTokenInterceptor(config: InternalAxiosRequestConfig) {
  const storedAuthData = getAuthDataLocalStorage();
  if (
    storedAuthData != null &&
    // we should not overwrite Authorization headers for requests to IdentityServer,
    // because there's Basic authorization header already
    !config.url?.endsWith('/connect/token') &&
    !config.url?.endsWith('/connect/revocation')
  ) {
    config.headers = config.headers ?? ({} as AxiosRequestHeaders);
    config.headers.Authorization = `Bearer ${storedAuthData.access_token}`;
  }
  return config;
}
