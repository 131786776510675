import { Descendant } from 'slate';

export type ParagraphElementArgs = { text: string };
export const createParagraphElement: (args?: ParagraphElementArgs) => Descendant = (args) => ({
  type: 'p',
  children: [
    {
      text: args?.text ?? '',
    },
  ],
});
