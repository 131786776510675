import React from 'react';

import styles from './StagePicker.module.scss';
import { Checkbox } from '@mui/material';

export type StageOption = {
  name: string;
  isSelected: boolean;
};

export type StagePickerProps = {
  stages: StageOption[];
  onChange(selectedStages: string[]): void;
};

export const StagePicker: React.FC<StagePickerProps> = (props) => {
  const { stages, onChange } = props;
  const allSelected = stages.every((x) => x.isSelected);

  const selectAll = () => onChange(stages.map((x) => x.name));
  const selectNone = () => onChange([]);

  return (
    <div className={styles.stagePicker}>
      {stages.length > 1 && (
        <span className={styles.allButton} onClick={() => (allSelected ? selectNone() : selectAll())}>
          {allSelected ? 'None' : 'All'}
        </span>
      )}
      {stages.map((stage) => (
        <div className={styles.stageOption} key={stage.name}>
          <Checkbox
            sx={{
              padding: 0,
            }}
            size="small"
            checked={stage.isSelected}
            onChange={(e) => {
              const selected = stages.filter((x) => (x.name === stage.name ? e.target.checked : x.isSelected));
              onChange(selected.map((x) => x.name));
            }}
          />
          <span className={styles.stageName}>{stage.name}</span>
        </div>
      ))}
    </div>
  );
};
