import { useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';

import { FirmwareApi, FirmwareFileDto } from 'api/firmware-api';
import { DownloadTokenApi } from 'api/downloadToken-api';
import { DotMenu, DotMenuOption } from 'components/DotMenu/DotMenu';
import { getDownloadToketQueryKey } from 'helpers/react-query/query-keys';
import { SelectedFirmwareFileContext } from '../FirmwarePage';

import menuStyles from 'styles/utils.module.scss';

export type FirmwareFileMenuProps = {
  file: FirmwareFileDto;
};

export const FirmwareFileMenu: React.FC<FirmwareFileMenuProps> = ({ file }) => {
  const options = useFirmwareFileOptions(file);
  return <DotMenu options={options} containerProps={{ className: menuStyles.tableRowEndMenu }} />;
};

const useFirmwareFileOptions: (file: FirmwareFileDto) => DotMenuOption[] = (file) => {
  const { setSelectedFile } = useContext(SelectedFirmwareFileContext);
  const isUsed = file.stages.length > 0;

  const downloadTokenQuery = useQuery(getDownloadToketQueryKey(), () => DownloadTokenApi.getToken(), {
    enabled: false,
  });

  const handleDownloadClick = async () => {
    const { data: downloadToken } = await downloadTokenQuery.refetch();
    if (!downloadToken) throw new Error('Failed to fetch download token.');
    const link = document.createElement('a');
    link.href = FirmwareApi.generateGettingFileUlr(file.id, downloadToken);
    link.download = file.fileName;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const opts: DotMenuOption[] = [
    {
      key: 'update',
      text: 'Update',
      onClick: () => setSelectedFile({ file: file, selectionType: 'editing' }),
    },
    {
      key: 'download',
      text: 'Download',
      onClick: () => handleDownloadClick(),
    },
    {
      key: 'delete',
      text: 'Delete',
      onClick: () => setSelectedFile({ file: file, selectionType: 'deleting' }),
      type: 'warning',
      disabled: isUsed,
      hint: { children: isUsed && 'Only unused files can be deleted.', openDelay: 350 },
    },
  ];

  return opts;
};
