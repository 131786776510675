import { Popper } from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode, useRef, useState } from 'react';

import HintIcon from 'assets/icons/questionmark.svg';

import styles from './AppHint.module.scss';

export type AppHintProps = {
  children?: ReactNode;
  component?: ReactNode;
  openDelay?: number;
  closeDelay?: number;
  className?: string;
  control?: {
    isOpen: boolean;
    setIsOpen?: (val: boolean) => void;
    openOnEnter?: boolean;
    closeOnLeave?: boolean;
  };
};

export const AppHint: React.FC<AppHintProps> = (props) => {
  const { children, component, openDelay, closeDelay, className, control } = props;
  const [hintOpen, setHintOpen] = useState(false);
  const hintAnchorRef = useRef(null);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const clear = () => {
    clearTimeout(timeoutRef.current);
    return true;
  };
  const schedule = (action: () => void, delay: number) => {
    clear();
    return (timeoutRef.current = setTimeout(action, delay));
  };

  const isControlled = control !== undefined;
  const setIsOpen = isControlled ? control?.setIsOpen ?? (() => {}) : setHintOpen;
  const open = () => schedule(() => setIsOpen(true), openDelay ?? 0);
  const close = () => schedule(() => setIsOpen(false), closeDelay ?? 100);

  return (
    <span
      ref={hintAnchorRef}
      className={clsx(styles.hintContainer, className)}
      onMouseEnter={() => (!isControlled || control.openOnEnter) && open()}
      onMouseLeave={() => (!isControlled || control.closeOnLeave) && close()}
    >
      {component ?? <HintIcon data-default-icon />}
      <Popper
        open={(isControlled ? control.isOpen : hintOpen) && !!children}
        anchorEl={hintAnchorRef.current}
        placement={'top'}
        modifiers={[{ name: 'offset', enabled: true, options: { offset: [0, 10] } }]}
      >
        <div className={styles.hintContent}>{children}</div>
      </Popper>
    </span>
  );
};
