import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import {
    backendUri,
    clientId,
    scopes,
    signInRedirectUri,
    signOutRedirectUri,
    signOutRedirectUriPopup,
} from './openid-settings';


let manager: UserManager | undefined;

export type SignInRedirectHandler = (user: User) => void;
export type SignOutRedirectHandler = () => void;

function getClientSettings(): UserManagerSettings {
    return {
        authority: backendUri,
        client_id: clientId,
        redirect_uri: signInRedirectUri,
        popup_redirect_uri: signInRedirectUri,
        post_logout_redirect_uri: signOutRedirectUri,
        popup_post_logout_redirect_uri: signOutRedirectUriPopup,
        response_type: 'code',
        filterProtocolClaims: true,
        loadUserInfo: false,
        scope: scopes,
        automaticSilentRenew: false,
    };
}

function getManager() {
    if (!manager) {
        manager = new UserManager(getClientSettings());
    }
    return manager;
}

export async function openExternalLoginPopup(provider: string) {
    try {
        return await getManager().signinPopup({
            extraQueryParams: { provider: provider, popup: true },
        } as any);
    } catch (e) {
        throw e;
    }
}

export async function redirectToLoginPage() {
    try {
        await getManager().signinRedirect();
    } catch (e) {
    }
}

export function handleAuthenticationSignInCallback(
    successCallback: (user: User) => void,
    errorCallback?: (e: unknown) => void,
) {
    if (window.location.search.includes('popup')) {
        completeAuthorizationPopup();
    } else {
        completeAuthorizationRedirect()
            .then((user) => {
                successCallback(user);
            })
            .catch((e) => {
                errorCallback?.(e);
            });
    }
    return true;
}

export async function completeAuthorizationPopup() {
    const user = getManager().signinPopupCallback(window.location.href);
    return user;
}

export async function completeAuthorizationRedirect() {
    return getManager().signinRedirectCallback(window.location.href);
}

export function handleAuthenticationSignOutCallback(signOutCallback: () => void) {
    if (window.location.search.includes('popup')) {
        getManager()
            .signoutPopupCallback()
    } else {
        getManager()
            .signoutRedirectCallback()
            .then(() => {
                signOutCallback();
            });
    }
}

export async function signOutRedirect() {
    await getManager().signoutRedirect();
}

export async function signOutPopup() {
    await getManager().signoutPopup({
        extraQueryParams: { popup: true },
    });
}
