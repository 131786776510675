import React, { useContext } from 'react';

import { NotificationDto, NotificationStatus } from 'api/notifications-api';
import { AlertContext } from 'components/CustomAlert';
import { DotMenu, DotMenuOption } from 'components/DotMenu/DotMenu';
import {
  useInvalidateNotificationsPageQuery,
  useNotificationsPublishMutation,
  useNotificationsUnpublishMutation,
} from 'helpers/react-query/notifications-query-hooks';
import { SelectedNotificationContext } from '../NotificationsPage/NotificationsPage';

import menuStyles from 'styles/utils.module.scss';

export const NotificationDotMenu: React.FC<{ notification: NotificationDto }> = ({ notification }) => {
  const options = useNotificationMenuOptions(notification);
  return <DotMenu options={options} containerProps={{ className: menuStyles.tableRowEndMenu }} />;
};

const useNotificationMenuOptions: (notification: NotificationDto) => DotMenuOption[] = (notification) => {
  const opts: DotMenuOption[] = [];
  const { id, status } = notification;

  const invalidate = useInvalidateNotificationsPageQuery();
  const notificationsPublishMutation = useNotificationsPublishMutation(invalidate);
  const notificationsUnpublishMutation = useNotificationsUnpublishMutation(invalidate);

  const { setSelectedNotification } = useContext(SelectedNotificationContext);
  const { setAlertMessage, setAlertState } = useContext(AlertContext);

  opts.push({
    text: 'Copy ID',
    key: 'CopyIDMenuItem',
    onClick: () => {
      navigator.clipboard.writeText(notification.id);
      setAlertMessage('Notification ID copied');
      setAlertState(true);
    },
  });

  if (status !== NotificationStatus.Disabled && status !== NotificationStatus.Draft) {
    opts.push({
      text: 'Disable',
      type: 'warning',
      key: 'DisableMenuItem',
      onClick: () => setSelectedNotification({ notification, state: 'Disable' }),
    });
  }

  if (status === NotificationStatus.Planned) {
    opts.push({
      text: 'Unpublish',
      key: 'UnpublishMenuItem',
      onClick: () => notificationsUnpublishMutation.mutate(id),
    });
  }

  if (status === NotificationStatus.Draft) {
    opts.push(
      {
        text: 'Publish',
        type: 'info',
        key: 'PublishMenuItem',
        onClick: () => notificationsPublishMutation.mutate(id),
      },
      {
        text: 'Edit',
        key: 'EditMenuItem',
        onClick: () => setSelectedNotification({ notification, state: 'Edit' }),
      },
      {
        text: 'Delete',
        key: 'DeleteMenuItem',
        type: 'danger',
        onClick: () => setSelectedNotification({ notification, state: 'Delete' }),
      },
    );
  }

  return opts;
};
