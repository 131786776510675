import React from 'react';

import { NotificationStatus } from 'api/notifications-api';
import Ellipse from 'assets/icons/ellipse.svg';
import { formatAsDateTime } from 'helpers/dateUtils';

import styles from './StatusInfo.module.scss';

export const StatusInfo: React.FC<{ status: NotificationStatus; startDate: string; endDate: string | null }> = ({
  status,
  endDate,
  startDate,
}) => {
  const activePeriod = `${formatAsDateTime(startDate)}\xa0\u2014\xa0${
    endDate === null ? 'Forever' : formatAsDateTime(endDate)
  }`;

  return (
    <div className={styles.container}>
      <div className={styles.info} data-notification-status={status}>
        <Ellipse className={styles.ellipse} />
        <div>{status}</div>
      </div>
      <div className={styles.period} title={activePeriod}>
        {activePeriod}
      </div>
    </div>
  );
};
