import { useInfiniteQuery } from '@tanstack/react-query';

import { PageDto } from 'api/common';
import { FirmwareApi, FirmwareFileDto, FirmwareQueryParameters } from 'api/firmware-api';
import { getFirmwarePageQueryKey } from './react-query/query-keys';
import { getNextPageParam } from './react-query/infinite-queries-utils';

export function useInfiniteFirmwareQuery(queryParams: Omit<FirmwareQueryParameters, 'pageNumber'>) {
  return useInfiniteQuery<PageDto<FirmwareFileDto>>(
    getFirmwarePageQueryKey(queryParams),
    async ({ pageParam = 1 }) =>
      await FirmwareApi.get({
        ...queryParams,
        pageNumber: pageParam,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, pages) => getNextPageParam(lastPage, pages, queryParams),
    },
  );
}
