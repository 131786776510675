import { Button, FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { NotificationBaseDto } from 'api/notifications-api';
import { TargetsDto } from 'api/target-api';
import { AppDateTimePicker } from 'components/AppDateTimePicker/AppDateTimePicker';
import { AppForm } from 'components/AppForm/AppForm';
import { CheckboxGroup } from 'components/CheckBoxGroup/CheckboxGroup';
import { Counter } from 'components/Counter/Counter';
import { Field } from 'components/Field/Field';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { RichEditor } from 'components/RichText/RichEditor/RichEditor';
import { getPlainText } from 'components/RichText/getPlainText';
import { UserRole } from 'constants/UserRoleTypes/UserRole';
import { UserRoleType, availableRoles, roleDescription } from 'constants/UserRoleTypes/utils';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { MsgUnexpectedError, UseApplicationFormReturn } from 'helpers/useApplicationForm';
import { useIsoCodes } from 'helpers/useIsoCodes';
import { MaxNotificationLength, validatePlainText } from '../../common/Constants';
import { OrganizationsHint } from '../../common/OrganizationsHint/OrganizationsHint';
import { TenantTargetModel } from '../../common/TenantColumns/TenantColumns';
import { TenantModal } from '../../common/TenantModal/TenantModal';
import { getTenantsAvailableForSelection, tenantsEqual } from '../../common/TenantModal/useSelectedTenants';
import { TenantsModalLink } from '../../common/TenantModalLink/TenantModalLink';
import { NotificationModel } from '../../common/utils';
import { StagesUnavailableHint } from './StagesUnavailableHint/StagesUnavailableHint';

import styles from './BaseNotificationForm.module.scss';
import { useNotificationTarget } from './useNotificationTarget';

export type NotificationBaseModel = NotificationModel<NotificationBaseDto>;

export type BaseNotificationFormProps = {
  title: string;
  form: UseApplicationFormReturn<NotificationBaseModel, any>;
  onClose: () => void;
  onSubmit: (
    data: NotificationBaseModel,
    tenantsAvailableForSelection: TenantTargetModel[],
    event?: React.BaseSyntheticEvent,
  ) => unknown | Promise<unknown>;
  setIsFormSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  richTextEditorNamespace?: string;
};

export type useNotificationTargetArgs = {
  form: UseApplicationFormReturn<NotificationBaseModel, any>;
  targets: TargetsDto | undefined;
};

export const BaseNotificationForm = (props: BaseNotificationFormProps) => {
  const { form, onSubmit: submitHandler, onClose, setIsFormSubmitting, title } = props;
  const availableTargetsQuery = useAvailableTargetsQuery();
  const targets = availableTargetsQuery.data;
  useEffect(() => {
    if (availableTargetsQuery.isError)
      form.setError('root', {
        message: MsgUnexpectedError,
      });
    else form.clearErrors('root');
  }, [availableTargetsQuery.isError]);

  const [isTenantsModalOpen, setIsTenantsModalOpen] = useState<boolean>(false);

  const {
    countriesAvailableForSelection,
    tenantsAvailableForSelection,
    existingTenants,
    onCountriesChange,
    onStagesChange,
  } = useNotificationTarget({
    form,
    targets,
  });
  const onSubmit = (data: NotificationBaseModel) => submitHandler(data, tenantsAvailableForSelection);

  const { register, handleSubmit, formState, control, getValues, setValue } = form;
  const { tenants, plainText, countries, stages, endDate, startDate } = form.watch();

  useEffect(() => {
    if (setIsFormSubmitting) setIsFormSubmitting(formState.isSubmitting);
  }, [formState.isSubmitting]);

  const actions = [
    <Button color="secondary" variant="outlined" key={'cancel'} onClick={onClose}>
      Cancel
    </Button>,
    <Button type="submit" key={'save'} disabled={availableTargetsQuery.isError}>
      Save
    </Button>,
  ];

  register('plainText', {
    required: {
      value: true,
      message: 'Notification text is required',
    },
    maxLength: {
      value: MaxNotificationLength,
      message: `Notification text length should not exceed ${MaxNotificationLength} characters`,
    },
    validate: validatePlainText,
  });
  const cultureField = register('cultures');
  const selectedRolesField = register('selectedRoles');
  const targetTypeField = register('targetType', {
    required: {
      value: true,
      message: 'Cultures target type is required.',
    },
  });
  const stagesField = register('stages');
  const countriesField = register('countries');
  const startDateField = register('startDate', {
    required: {
      value: true,
      message: 'Notification start date is required',
    },
    deps: ['endDate'],
  });
  const endDateField = register('endDate', {
    validate: (val) => {
      return val && endDate && startDate > val ? 'Start date should precede end date' : true;
    },
  });

  const richTextHasError = () => formState.errors.plainText !== undefined;
  const plainTextLength = plainText?.trim().length === 0 ? 0 : plainText?.length ?? 0;
  const richTextHelperText = richTextHasError() ? (
    formState.errors.plainText?.message
  ) : (
    <Counter left={plainTextLength} right={MaxNotificationLength} />
  );

  const { getCountryName, getLanguageName } = useIsoCodes();
  const theme = useTheme();
  return (
    <AppForm
      title={title}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      actions={actions}
      helperText={formState.errors.root?.message}
      error={!!formState.errors.root}
      isLoading={formState.isSubmitting || availableTargetsQuery.isFetching}
    >
      <Field title={'Text'}>
        <RichEditor
          onChange={(val) => {
            form.setValue('richText', val);
            const plainText = getPlainText(val);
            if (plainText !== form.watch('plainText')) form.setValue('plainText', plainText, { shouldValidate: true });
          }}
          initialValue={form.watch().richText}
          className={styles.richEditor}
          error={richTextHasError()}
          helperText={richTextHelperText as string}
        />
      </Field>
      <Field title={'Start date'}>
        <Controller
          name={startDateField.name}
          control={control}
          render={({ field }) => (
            <AppDateTimePicker
              error={!!formState.errors.startDate}
              helperText={formState.errors.startDate?.message as any}
              {...field}
            />
          )}
        />
      </Field>
      <Field title={'End date'}>
        <Controller
          name={endDateField.name}
          control={control}
          render={({ field }) => (
            <AppDateTimePicker
              error={!!formState.errors.endDate}
              helperText={(formState.errors.endDate?.message as any) ?? 'Optional'}
              {...field}
            />
          )}
        />
      </Field>
      <Field title={'Target'} fieldClassName={styles.radioGroup}>
        <Controller
          control={control}
          name={targetTypeField.name}
          render={({ field }) => (
            <RadioGroup row style={{ justifyContent: 'space-between' }} {...field}>
              <FormControlLabel value={'All'} control={<Radio />} label={'All users'} />
              <FormControlLabel value={'Specific'} control={<Radio />} label={'Certain group'} />
            </RadioGroup>
          )}
        />
      </Field>
      {getValues(targetTypeField.name) === 'Specific' && (
        <>
          <Field title={'Languages'}>
            <Controller
              name={cultureField.name}
              control={control}
              render={({ field }) => (
                <MultiSelect
                  onChange={field.onChange}
                  placeholder={'All languages'}
                  options={targets?.cultures ?? []}
                  helperText={formState.errors.cultures?.message as any}
                  error={!!formState.errors.cultures}
                  defaultValue={getValues(cultureField.name) ?? undefined}
                  labelStrategy={getLanguageName}
                />
              )}
            />
          </Field>
          <Field title={'Roles'}>
            <Controller
              control={control}
              name={selectedRolesField.name}
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup
                  options={availableRoles.map((availableRole) => {
                    return {
                      value: UserRole[availableRole],
                      label: roleDescription.get(availableRole) ?? 'Unknown',
                      selected: value.includes(availableRole),
                    };
                  })}
                  onChange={(selectedRole, checked) => {
                    const role = UserRole[selectedRole as UserRoleType];
                    if (checked) {
                      onChange([...value, role]);
                    } else {
                      onChange([...value.filter((item) => item !== role)]);
                    }
                  }}
                />
              )}
            />
          </Field>
          <Field title={'Stages'} hint={<OrganizationsHint attribute="stages" />}>
            <Controller
              control={control}
              name={stagesField.name}
              render={({ field }) => (
                <MultiSelect
                  onChange={(val) => {
                    onStagesChange(val);
                    field.onChange(val);
                  }}
                  placeholder={'All stages'}
                  options={targets?.stages.filter((x) => x.isAvailable).map((x) => x.name) ?? []}
                  helperTextColor={targets?.stages.some((x) => !x.isAvailable) ? theme.palette.warning.main : undefined}
                  helperText={
                    formState.errors.stages?.message ??
                    ((<StagesUnavailableHint stages={targets?.stages ?? []} />) as any)
                  }
                  error={!!formState.errors.stages}
                  value={field.value}
                />
              )}
            />
          </Field>
          <Field title={'Countries'} hint={<OrganizationsHint attribute="countries" />}>
            <Controller
              control={control}
              name={countriesField.name}
              render={({ field }) => (
                <MultiSelect
                  onChange={(val) => {
                    onCountriesChange(val);
                    field.onChange(val);
                  }}
                  placeholder={'All countries'}
                  options={countriesAvailableForSelection}
                  helperText={(formState.errors.countries?.message as any) ?? 'Filtered by selected stages'}
                  error={!!formState.errors.countries}
                  value={field.value}
                  labelStrategy={getCountryName}
                />
              )}
            />
          </Field>
          <Field title={'Organizations'}>
            {tenants && <TenantsModalLink selectedTenants={tenants} onEditClick={() => setIsTenantsModalOpen(true)} />}
            <TenantModal
              open={isTenantsModalOpen}
              onClose={() => setIsTenantsModalOpen(false)}
              onSave={(selectedTenants) => {
                setValue(
                  'tenants',
                  selectedTenants.filter((x) => x.isSelected).length === tenantsAvailableForSelection.length
                    ? []
                    : selectedTenants.filter((x) => x.isSelected),
                );
                setIsTenantsModalOpen(false);
              }}
              selectedStages={stages}
              selectedCountries={countries}
              existingTenants={existingTenants}
            />
          </Field>
        </>
      )}
    </AppForm>
  );
};
