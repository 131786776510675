import { SxProps, Theme } from '@mui/material';
import { DatePicker, DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers-pro';
import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';

export type AppDateTimePickerProps = {
  error?: boolean;
  helperText?: string;
  sx?: SxProps<Theme>;
  type?: 'dateOnly' | 'dateTime';
} & Partial<Omit<ControllerRenderProps, 'ref'>>;

export const AppDateTimePicker = React.forwardRef<HTMLInputElement, AppDateTimePickerProps>((props, ref) => {
  const { error, helperText, type = 'dateTime', sx, ...rest } = props;

  const dateTimePickerProps = {
    viewRenderers: {
      hours: renderTimeViewClock,
      minutes: renderTimeViewClock,
      seconds: renderTimeViewClock,
    },
    ampm: false,
  };
  const pickerProps = {
    sx: sx,
    slotProps: {
      textField: {
        error: error,
        helperText: helperText,
      },
    },
    ref: ref,
    ...rest,
  };

  return (
    <>
      {type === 'dateTime' ? (
        <DateTimePicker {...pickerProps} {...dateTimePickerProps}  />
      ) : (
        <DatePicker {...pickerProps} />
      )}
    </>
  );
});
