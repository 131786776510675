import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ConnectQueryClient } from 'helpers/react-query/ConnectQueryClient';
import { App } from '../App';
import { Links } from './routes';

export const AppRouter = () => {
  return (
    <ConnectQueryClient>
      <BrowserRouter>
        <Routes>
          <Route path={Links.Unauthorized.Login.route} element={<></>} />
          <Route path={'/*'} element={<App />} />
        </Routes>
      </BrowserRouter>
    </ConnectQueryClient>
  );
};
