import { InfiniteData } from '@tanstack/react-query';
import { useMemo } from 'react';

export function useFlattenedPageData<TData, TModel>(
  rawData: InfiniteData<TData> | undefined,
  pageParser: (a: TData) => TModel[],
): TModel[] {
  return useMemo(() => {
    return rawData?.pages.map(pageParser).flatMap((page) => page) ?? [];
  }, [rawData]);
}
