import { clsx } from 'clsx';
import * as React from 'react';
import { useSlate } from 'slate-react';

import BoldIcon from 'assets/icons/bold.svg';
import ItalicIcon from 'assets/icons/italic.svg';
import LinkIcon from 'assets/icons/link.svg';
import RedoIcon from 'assets/icons/redo.svg';
import StrikethroughIcon from 'assets/icons/strikethrough.svg';
import UndoIcon from 'assets/icons/undo.svg';
import { RichElementButton, RichButton, RichFormatButton } from './RichButton/RichButton';

import styles from './RichToolbar.module.scss';

export const RichToolbar = () => {
  const editor = useSlate();

  const handleUndo = () => {
    editor.undo();
  };

  const handleRedo = () => {
    editor.redo();
  };

  const canRedo = editor.history.redos.length > 0;
  const canUndo = editor.history.undos.length > 0;

  return (
    <div className={styles.richToolbar}>
      <RichToolbarSection>
        <RichFormatButton format="bold">
          <BoldIcon />
        </RichFormatButton>
        <RichFormatButton format="italic">
          <ItalicIcon />
        </RichFormatButton>
        <RichFormatButton format="strikethrough">
          <StrikethroughIcon />
        </RichFormatButton>
      </RichToolbarSection>

      <RichToolbarSection>
        <RichElementButton format="link">
          <LinkIcon />
        </RichElementButton>
      </RichToolbarSection>

      <RichToolbarSection>
        <RichButton disabled={!canUndo} onClick={handleUndo}>
          <UndoIcon />
        </RichButton>
        <RichButton disabled={!canRedo} onClick={handleRedo}>
          <RedoIcon />
        </RichButton>
      </RichToolbarSection>
    </div>
  );
};

export const RichToolbarSection: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(styles.richToolbarSection, className)} {...rest}>
      {children}
    </div>
  );
};
