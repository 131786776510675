import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { Descendant, createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';

import { useElementRenderer } from '../Hooks/useElementRenderer';
import { CustomElement, useLeafRenderer } from '../Hooks/useLeafRenderer';
import { withInlines } from '../Hooks/withInlines';
import { withSingleLine } from '../Hooks/withSingleLine';

import textStyles from '../RichText.module.scss';

export const RichViewer: React.FC<{
  className?: string;
  initialValue: Descendant[];
  customElements: CustomElement[];
}> = (props) => {
  const renderElement = useElementRenderer(true);
  const renderLeaf = useLeafRenderer(props.customElements);
  const editor = useMemo(() => withReact(withInlines(withSingleLine(createEditor()))), []);
  const [initialValue, setInitialValue] = useState(props.initialValue);

  useEffect(() => {
    setInitialValue(props.initialValue);
  }, [props.initialValue]);

  // It is fix of view content refresh, Transform.insertNodes (editor.apply) behaves little bit strange,
  // it does not insert grand children.
  // Here we simply show nothing once that forces react to recreate Slates tag.
  if (initialValue !== props.initialValue) {
    return null;
  }

  return (
    <Slate editor={editor} initialValue={props.initialValue}>
      <Editable
        className={clsx(textStyles.richText, props.className)}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  );
};
