import { TextField } from '@mui/material';
import clsx from 'clsx';
import React, { ReactNode, useMemo } from 'react';
import { Descendant, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable as SlateEditable, withReact } from 'slate-react';
import { EditableProps } from 'slate-react/dist/components/editable';

import { htmlToSlate } from '.././Converters';
import { useElementRenderer } from '.././Hooks/useElementRenderer';
import { useLeafRenderer } from '.././Hooks/useLeafRenderer';
import { createParagraphElement } from '../Elements/ParagraphElement';
import { useHotKeys } from '../Hooks/useHotKeys';
import { withInlines } from '../Hooks/withInlines';
import { withSingleLine } from '../Hooks/withSingleLine';
import { RichToolbar } from '../RichToolbar/RichToolbar';

import textStyles from '../RichText.module.scss';
import styles from './RichEditor.module.scss';

export type RichEditorProps = {
  id?: string;
  className?: string;
  initialValue: Descendant[];
  onChange: ((value: Descendant[]) => void) | undefined;
  placeholder?: string;
  helperText?: ReactNode;
  error?: boolean;
};

export const RichEditor: React.FC<RichEditorProps> = (props) => {
  const { placeholder, className, onChange, initialValue, error, helperText } = props;
  const editor = useMemo(() => withReact(withHistory(withSingleLine(withInlines(createEditor())))), []);

  const renderElement = useElementRenderer(false);
  const renderLeaf = useLeafRenderer();

  const handleHotKey = useHotKeys(editor);
  const handleEditorChange = (value: Descendant[]) => onChange?.(value);
  return (
    <Slate editor={editor} initialValue={initialValue} onChange={handleEditorChange}>
      <div className={clsx(styles.richEditor, styles.richContent, className)}>
        <RichToolbar />
        <TextField
          InputProps={{
            inputComponent: Editable as any,
            inputProps: {
              className: styles.richEditorInput,
              renderElement: renderElement,
              renderLeaf: renderLeaf,
              placeholder: placeholder,
              autoFocus: true,
              onKeyDown: handleHotKey,
            },
            className: clsx(styles.richEditorContent, textStyles.richText),
          }}
          multiline={true}
          fullWidth={true}
          error={error}
          helperText={helperText}
        />
      </div>
    </Slate>
  );
};

const Editable = React.forwardRef<EditableProps, EditableProps>((props, ref) => {
  const { className, ...rest } = props;
  return <SlateEditable {...rest} className={clsx(styles.editable, className)} />;
});

export const getRichTextContent = (content?: string): Descendant[] =>
  content ? htmlToSlate(content) : DefaultEditorContent;
const DefaultEditorContent: Descendant[] = [createParagraphElement()];
