import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import { UploadFirmwareFileDto } from 'api/firmware-api';
import { FirmwareFileActions } from '../useUploadFirmwareFilesForm/useUploadFirmwareFilesForm';
import { useUploadFirmwareFilesColumns } from './useUploadFirmwareFilesColumns/useUploadFirmwareFilesColumns';

export type UploadFirmwareFilesTableAttributes = {
  stages: string[];
  actions: FirmwareFileActions;
  files: UploadFirmwareFileDto[];
};

export const useUploadFirmwareFilesTable = ({ actions, files, stages }: UploadFirmwareFilesTableAttributes) => {
  const columns = useUploadFirmwareFilesColumns({
    stages,
    actions,
  });

  return useReactTable<UploadFirmwareFileDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: files,
    columns: columns,
  });
};
