import { Editor, Text } from 'slate';

import { isUrl } from 'helpers/urlUtils';
import { ElementType } from '../CustomTypes';
import { wrapLink } from '../Elements/LinkElement/LinkElement';

const InlineElements: ElementType[] = ['link'];

export const withInlines = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => InlineElements.includes(element.type) || Text.isText(element) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
