import { htmlToSlate as originalHtmlToSlate, slateToHtml as originalSlateToHtml } from '@slate-serializers/html';
import { Config } from '@slate-serializers/html/src/lib/serializers/htmlToSlate/config/types';
import { Descendant, Element as SlateElement, Text as SlateText } from 'slate';

export const htmlToSlate = (content: string) => {
  const state = originalHtmlToSlate(content) as Descendant[];
  const statePostProcess = (items: Descendant[]) => {
    // Slate do not support direct text and element in list item, so cover it by p tag.
    items.forEach((x) => {
      if (SlateElement.isElement(x)) {
        if (x.type === 'li' && x.children.some((x) => SlateElement.isElement(x))) {
          x.children = x.children.map((y) => (SlateText.isText(y) ? { type: 'p', children: [y] } : y));
        }
        statePostProcess(x.children);
      }
    });
  };

  statePostProcess(state);
  return state;
};

export const slateToHtml = (state: Descendant[]) => {
  return originalSlateToHtml(state);
};
