import { QueryKey } from '@tanstack/react-query';

import { FirmwareQueryParameters } from 'api/firmware-api';
import { NotificationsQueryParameters } from 'api/notifications-api';

const getPageQueryKey = (baseKeys: string[], queryParams: any): QueryKey =>
  queryParams ? [...baseKeys, JSON.stringify(queryParams)] : baseKeys;

export const getNotificationsPageQueryKey = (queryParams?: NotificationsQueryParameters): QueryKey => {
  const baseKeys = ['notifications', 'list'];
  return queryParams ? [...baseKeys, JSON.stringify(queryParams)] : baseKeys;
};
export const getFirmwarePageQueryKey = (queryParams?: FirmwareQueryParameters): QueryKey =>
  getPageQueryKey(['firmware', 'list'], queryParams);

export const getAvailableTargetsQueryKey = (): QueryKey => ['targets', 'get'];

export const getDownloadToketQueryKey = (): QueryKey => ['downloadToken', 'get'];
