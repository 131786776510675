import React from 'react';

import styles from './Counter.module.scss';

export type CounterProps = {
  left: React.ReactNode;
  right: React.ReactNode;
};

export const Counter: React.FC<CounterProps> = (props) => {
  const { left, right } = props;
  return (
    <span className={styles.Counter}>
      {left}/{right}
    </span>
  );
};
