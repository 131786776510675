import { IconButton, Menu, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import Kebab from 'assets/icons/kebab.svg';

import styles from './DotMenu.module.scss';
import { AppHint, AppHintProps } from 'components/AppHint/AppHint';

type OwnProps = {
  options: DotMenuOption[];
  buttonClassName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
};

export type DotMenuOption = {
  key: string;
  text: React.ReactNode;
  type?: 'neutral' | 'info' | 'warning' | 'danger';
  disabled?: boolean;
  hint?: Omit<AppHintProps, 'component'>;
  onClick: () => void;
};

export const DotMenu: React.FC<OwnProps> = ({ options, containerProps, buttonClassName }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = !!anchorEl;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (options.length > 0) setAnchorEl(event.currentTarget);
    },
    [options],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div {...containerProps} data-inactive={options.length === 0}>
      <IconButton
        disableRipple={true}
        disabled={options.length === 0}
        onClick={handleClick}
        className={clsx(styles.button, buttonClassName)}
      >
        <Kebab />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        open={isOpen}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: { boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.15)', borderRadius: 0 },
          },
        }}
      >
        {options.map((option) => (
          <AppHint
            key={option.key}
            component={
              <MenuItem
                className={styles.menuItem}
                data-option-type={option.type ?? 'neutral'}
                disabled={option.disabled}
                onClick={() => {
                  option.onClick();
                  handleClose();
                }}
              >
                {option.text}
              </MenuItem>
            }
            {...option.hint}
          />
        ))}
      </Menu>
    </div>
  );
};
