import { Editor, Text as SlateText } from 'slate';
import { useSlate } from 'slate-react';

export type SlateTextFormat = keyof Omit<SlateText, 'text'>;

export const useFormatActions = (format: SlateTextFormat) => {
  const editor = useSlate();

  const isActive = () => isFormatActive(editor, format);
  const handleClick = () => handleFormatAction(editor, format);

  return { isSelected: isActive(), handleClick };
};

export const handleFormatAction = (editor: Editor, format: SlateTextFormat) => {
  // here we simply enable or disable one of the properties of the object
  // example:
  // before: { type: 'p', children: { text: 'Hello', bold: true}}
  // after: { type: 'p', children: { text: 'Hello', bold: false}}
  if (isFormatActive(editor, format)) Editor.removeMark(editor, format);
  else Editor.addMark(editor, format, true);
};

const isFormatActive = (editor: Editor, format: SlateTextFormat) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
