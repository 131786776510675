import React from 'react';
import styles from './Loading.module.scss';
import { BusyContainer, BusyIndicator } from '../BusyIndicator/BusyIndicator';

export type LoadingProps = {
  loading: boolean;
  children?: React.ReactNode;
  className?: string;
  progress?: number;
};

export const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <>
      {props.children}
      {props.loading && (
        <BusyContainer progress={props.progress} className={styles.container} position="center">
          {<BusyIndicator lockUI={true} />}
        </BusyContainer>
      )}
    </>
  );
};
