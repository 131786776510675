import { useIsFetching } from '@tanstack/react-query';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { createContext, useContext, useState } from 'react';
import {
  ArrayParam,
  NumberParam,
  createEnumArrayParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from 'react-router-url-params';

import { SortingType } from 'api/common';
import {
  FirmwareFileDto,
  FirmwareGenerations,
  FirmwareQueryParameters,
  FirmwareSortingAttribute,
  FirmwareSortingAttributes,
} from 'api/firmware-api';
import { AppTable } from 'components/AppTable/AppTable';
import { Loading } from 'components/Loading/Loading';
import { useSortingControl } from 'components/SortableHeader/SortableHeader';
import { EditFirmwareModal } from 'features/FirmwarePage/Forms/EditFirmwareModal';
import { TopPanel } from 'features/FirmwarePage/TopPanel/TopPanel';
import { useInfiniteScrolling } from 'helpers/InfiniteScrolling';
import { useAvailableTargetsQuery } from 'helpers/react-query/notifications-query-hooks';
import { useInfiniteFirmwareQuery } from 'helpers/useInfiniteFirmwareQuery';
import { getDownloadToketQueryKey } from '../../../helpers/react-query/query-keys';
import { DeleteFirmwareModal } from '../Forms/DeleteFirmwareModal';
import { useFirmwarePageColumns } from './columns';

import styles from './FirmwarePage.module.scss';

const defaultPageParams: FirmwareQueryParameters = {
  pageSize: 16,
  sortingType: SortingType.Descending,
  stages: [],
  generations: [],
  sortingAttribute: 'uploadedAt',
};

export type FirmwareFileSelectionType = 'editing' | 'deleting';
export type SelectedFirmwareFile = {
  file: FirmwareFileDto;
  selectionType: FirmwareFileSelectionType;
};

type SelectedFirmwareFileContext = {
  selectedFile: SelectedFirmwareFile | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<SelectedFirmwareFile | null>>;
};

export const SelectedFirmwareFileContext = createContext<SelectedFirmwareFileContext>({
  selectedFile: null,
  setSelectedFile: () => undefined,
});

export const FirmwarePage: React.FC = () => {
  const stages = useAvailableTargetsQuery()?.data?.stages?.map((x) => x.name) ?? [];
  const [selectedFile, setSelectedFile] = useState<SelectedFirmwareFile | null>(null);
  const [
    { selectedGenerations, selectedStages: stagesParam, selectedSortingType, selectedSortingAttribute },
    setQueryParams,
  ] = useQueryParams({
    selectedStages: withDefault(ArrayParam, []),
    selectedGenerations: withDefault(createEnumArrayParam(FirmwareGenerations), []),
    selectedSortingType: withDefault(NumberParam, defaultPageParams.sortingType),
    selectedSortingAttribute: withDefault(
      createEnumParam([...FirmwareSortingAttributes]),
      defaultPageParams.sortingAttribute,
    ),
  });
  const isDownloadFileFetching = useIsFetching({
    queryKey: getDownloadToketQueryKey(),
  });

  const selectedStages = stagesParam.filter((x) => x) as string[];

  const query = useInfiniteFirmwareQuery({
    ...defaultPageParams,
    stages: selectedStages,
    generations: selectedGenerations,
    sortingType: selectedSortingType,
    sortingAttribute: selectedSortingAttribute,
  });
  const { isFetching } = query;

  const { ref, flatData } = useInfiniteScrolling(query, ({ items: sharedFiles }) => sharedFiles);

  const getSortingControl = (sortingAttribute: FirmwareSortingAttribute) =>
    useSortingControl(
      selectedSortingType,
      (sortingType: SortingType) =>
        setQueryParams({ selectedSortingType: sortingType, selectedSortingAttribute: sortingAttribute }),
      sortingAttribute === selectedSortingAttribute,
    );
  const columns = useFirmwarePageColumns({
    uploadedAtSortingControl: getSortingControl('uploadedAt'),
    versionSortingControl: getSortingControl('version'),
  });
  const table = useReactTable<FirmwareFileDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: flatData,
    columns: columns,
  });

  return (
    <SelectedFirmwareFileContext.Provider
      value={{
        selectedFile: selectedFile,
        setSelectedFile: setSelectedFile,
      }}
    >
      <Loading loading={isFetching || !!isDownloadFileFetching} />
      <TopPanel
        allStages={stages}
        selectedStages={selectedStages}
        onSelectedStagesChange={(stages) => setQueryParams({ selectedStages: stages })}
        selectedGenerations={selectedGenerations}
        onSelectedGenerationsChange={(generations) => setQueryParams({ selectedGenerations: generations })}
      />
      <AppTable
        table={table}
        estimateRowSize={() => 60}
        classNames={{ bodyCell: styles.cell, bodyRow: styles.row }}
        bottomRef={ref}
      />
      <FirmwarePageForms />
    </SelectedFirmwareFileContext.Provider>
  );
};

const FirmwarePageForms = () =>
  useContext(SelectedFirmwareFileContext)?.selectedFile ? (
    <>
      <EditFirmwareModal />
      <DeleteFirmwareModal />
    </>
  ) : null;
