import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export type TargetsDto = {
  stages: StageTarget[];
  tenants: TenantTarget[];
  countries: string[];
  cultures: string[];
};

export type StageTarget = {
  name: string;
  isAvailable: boolean;
};

export type TenantTarget = {
  stage: string;
  tenant: string;
  name: string;
  countryCode: string;
  languageCode: string;
};

export const TargetApi = {
  async GetAvailableTargets(options?: AxiosRequestConfig): Promise<TargetsDto> {
    const result: AxiosResponse<TargetsDto> = await axios.get('api/target/targets', options);

    return result.data;
  },
};
