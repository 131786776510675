import { parseISO } from 'date-fns';
import React from 'react';

import { slateToHtml } from '@slate-serializers/html';
import { NotificationDto, NotificationEditDto, NotificationsApi } from 'api/notifications-api';
import { getRichTextContent } from 'components/RichText/RichEditor/RichEditor';
import { availableRoles, parseUserRoles } from 'constants/UserRoleTypes/utils';
import { useApplicationForm } from 'helpers/useApplicationForm';
import { TenantTargetModel } from '../../common/TenantColumns/TenantColumns';
import { NotificationModel } from '../../common/utils';
import { BaseNotificationForm } from '../BaseNotificationForm/BaseNotificationForm';

type EditNotificationFormProps = {
  notificationData: NotificationDto;
  setIsFormSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccessful: () => void;
  onClose: () => void;
};

const getUnique = <TVal,>(values: TVal[], acessor: (val: TVal) => any) =>
  !!values ? [...new Set(values.map(acessor))] : [];

type NotificationEditModel = Omit<NotificationModel<NotificationEditDto>, 'id'>;

export const EditNotificationForm: React.FC<EditNotificationFormProps> = (props) => {
  const { notificationData, onSubmitSuccessful, onClose, setIsFormSubmitting } = props;

  const form = useApplicationForm<NotificationEditModel>({
    values: {
      richText: getRichTextContent(notificationData.richText),
      plainText: notificationData.plainText,
      startDate: parseISO(notificationData.startDate),
      endDate: notificationData.endDate ? parseISO(notificationData.endDate) : null,
      targetType: notificationData.cultures === null && notificationData.tenants === null ? 'All' : 'Specific',
      cultures: notificationData.cultures,
      stages: getUnique(notificationData.tenants ?? [], (x) => x.stage),
      countries: getUnique(notificationData.tenants ?? [], (x) => x.countryCode),
      tenants: notificationData.tenants ?? [],
      roles: notificationData.roles,
      selectedRoles: notificationData.roles === null ? [] : parseUserRoles(notificationData.roles.toString()),
    },
    mode: 'all',
  });

  const onSubmit = async (data: NotificationEditModel, tenantsAvailableForSelection: TenantTargetModel[]) => {
    const { targetType, selectedRoles, ...submitData } = data;

    let tenants = null;
    if (targetType !== 'All')
      tenants = submitData.tenants?.length === 0 ? tenantsAvailableForSelection : submitData.tenants;

    let cultures = null;
    if (targetType !== 'All' && submitData.cultures !== null && submitData.cultures.length !== 0)
      cultures = submitData.cultures;

    let roles = availableRoles.reduce((prev: any, curr: any) => prev | curr);
    if (targetType !== 'All' && selectedRoles.length > 0)
      roles = selectedRoles.reduce((prev: any, curr: any) => prev | curr);

    await NotificationsApi.editNotification({
      ...submitData,
      cultures: cultures,
      tenants: tenants,
      roles: roles,
      richText: slateToHtml(submitData.richText),
      id: notificationData.id,
    });
    onSubmitSuccessful();
  };

  return (
    <BaseNotificationForm
      form={form}
      onClose={onClose}
      title="Edit notification"
      setIsFormSubmitting={setIsFormSubmitting}
      onSubmit={onSubmit}
    />
  );
};
