import JSZip, { JSZipObject } from 'jszip';

import { DeviceFirmwareGeneration, GenerationsInfoMap, UploadFirmwareFileDto } from 'api/firmware-api';
import { getExtension, unzipFile } from 'helpers/fileUtils';
import { Result, splitResults } from 'helpers/resultUtils';
import { useMemo } from 'react';

type ParsedFirmwareFile = {
  file: File;
  firmwareGeneration: DeviceFirmwareGeneration;
  version: string;
};

export const transformProcessedFiles = (parsedFiles: ParsedFirmwareFile[], defaultReleaseDate: Date) =>
  parsedFiles.map((file) => ({ ...file, stages: [], releaseDate: defaultReleaseDate }));

export const useFirmwareFilesProcessor = () => useMemo(() => tryProcessFirmwareFiles, []);

const tryProcessFirmwareFiles = async (archives: File[]) =>
  splitResults(await Promise.all(tryGetFirmwareFiles(archives)));

const tryGetFirmwareFiles = (archives: File[]) => archives.map(tryProcessArchive);

const tryProcessArchive = async (archive: File): Promise<Result<ParsedFirmwareFile, string>> => {
  try {
    return { hasError: false, result: await processArchive(archive) };
  } catch (error: any) {
    return { hasError: true, error: `An error occured when processing ${archive.name}: ${error}` };
  }
};

const processArchive = async (archive: File): Promise<ParsedFirmwareFile> => {
  validateExtension(archive);

  const files = await extractFiles(archive);

  return {
    version: await getVersion(files),
    ...(await getFirmwareFileAndGeneration(files)),
  };
};

const extractFiles = async (archive: File) => Object.values((await JSZip.loadAsync(archive)).files);

const validateExtension = (file: File) => {
  if (getExtension(file.name).toLocaleLowerCase() !== 'zip')
    throw `wrong extension: ${getExtension(file.name)}. Only .zip/.ZIP files are supported.`;
};

const getVersion = async (files: JSZipObject[]) => {
  const versionFile = files.find((x) => x.name.match(/.*version.txt/));
  if (!versionFile) throw 'archive is missing version.txt file.';

  return await versionFile.async('text');
};

const getFirmwareFileAndGeneration = async (files: JSZipObject[]) => {
  const firmware = files.map(parseFirmwareFile).find((x) => x);
  if (!firmware) throw 'archive does not contain any firmware update files.';

  return { file: await unzipFile(firmware.file), firmwareGeneration: firmware.generation };
};

const parseFirmwareFile = (file: JSZipObject) => {
  const info = getFirmwareFileInfo(file);
  return info && { generation: info.firmwareGeneration, file };
};

const getFirmwareFileInfo = (file: JSZipObject) =>
  GenerationsInfoMap.find((generation) => file.name.match(generation.firmwareFileName));
