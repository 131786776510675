import { UserRole } from './UserRole';

export type UserRoleType = keyof typeof UserRole;

export const availableRoles = [UserRole.OrganisationManager, UserRole.MedicalProfessional, UserRole.Technician];

export const rolesDescription = (roles: UserRole[]): string[] => {
  return roles.map((role) => roleDescription.get(role)!);
};

export const roleDescription = new Map<UserRole, string>([
  [UserRole.OrganisationManager, 'Organization administrator'],
  [UserRole.MedicalProfessional, 'Patient carer'],
  [UserRole.PortalOrganisationManager, 'Employee CS'],
  [UserRole.Technician, 'Device administrator'],
  [UserRole.PortalUserManager, 'Admin CS'],
  // No description in SPA
  [UserRole.PatientAppUser, ''],
  [UserRole.PreRegistrationManager, 'ABAS CS'],
  [UserRole.PrismaSmartConfigurationEditor, 'prisma SMART, prisma SOFT (WM 90 TD)'],
  [UserRole.PrismaLineSleepConfigurationEditor, 'prismaLINE (WM 100 TD) Sleep'],
  [UserRole.PrismaVentConfigurationEditor, 'prisma VENT (WM 110/120 TD)'],
  [UserRole.PrismaLineVentiConfigurationEditor, 'prismaLINE (WM 100 TD) Ventilation'],
  // No description in SPA
  [UserRole.PrismaSmartTelemonitoringSwitcher, ''],
]);

export const parseUserRoles = (userRoles: string): UserRole[] => {
  const roles = userRoles.split(',').map((x) => x.trim()) as UserRoleType[];
  return roles.map((role) => UserRole[role]);
};

export const rolesToView = (inputRoles: UserRole | null): string[] | null => {
  const roles = inputRoles && parseUserRoles(inputRoles.toString());
  return JSON.stringify(roles) === JSON.stringify(availableRoles)
    ? null
    : roles !== UserRole.None && roles !== null
    ? rolesDescription(roles)
    : null;
};
