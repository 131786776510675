import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import React, { createContext, useState } from 'react';
import { NumberParam, StringParam, createEnumArrayParam, useQueryParams, withDefault } from 'react-router-url-params';

import { SortingType } from 'api/common';
import { NotificationDto, NotificationStatus, NotificationsQueryParameters } from 'api/notifications-api';
import { AppModal } from 'components/AppModal/AppModal';
import { AppTable } from 'components/AppTable/AppTable';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { Loading } from 'components/Loading/Loading';
import { useSortingControl } from 'components/SortableHeader/SortableHeader';
import { useInfiniteScrolling } from 'helpers/InfiniteScrolling';
import {
  useInvalidateNotificationsPageQuery,
  useNotificationsDeleteMutation,
  useNotificationsDisableMutation,
} from 'helpers/react-query/notifications-query-hooks';
import { useInfiniteNotificationsQuery } from 'helpers/useInfiniteNotificationsQuery';
import { EditNotificationForm } from '../Forms/EditNotificationForm/EditNotificationForm';
import { NotificationTargetDisplay } from '../NotificationTargetDisplay/NotificationTargetDisplay';
import { TopPanel } from '../TopPanel/TopPanel';
import { useNotificationsPageColumns } from './columns';

const defaultPageParams: NotificationsQueryParameters = {
  pageSize: 16,
  sortingType: SortingType.Descending,
  notificationsStatuses: [],
};

export type SelectedNotificationState = 'Delete' | 'Disable' | 'Edit' | 'ViewTarget';
export type SelectedNotification = {
  notification: NotificationDto;
  state: SelectedNotificationState;
};

type SelectedNotificationContext = {
  selectedNotification: SelectedNotification | null;
  setSelectedNotification: React.Dispatch<React.SetStateAction<SelectedNotification | null>>;
};

export const SelectedNotificationContext = createContext<SelectedNotificationContext>({
  selectedNotification: null,
  setSelectedNotification: () => undefined,
});

const NotificationStatusesArrayEnumParam = createEnumArrayParam(
  Object.keys(NotificationStatus) as NotificationStatus[],
);

export const NotificationsPage: React.FC = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [{ searchQuery, selectedNotificationStatuses, selectedSortingType }, setQueryParams] = useQueryParams({
    searchQuery: withDefault(StringParam, ''),
    selectedNotificationStatuses: withDefault(NotificationStatusesArrayEnumParam, []),
    selectedSortingType: withDefault(NumberParam, defaultPageParams.sortingType),
  });

  const invalidate = useInvalidateNotificationsPageQuery();
  const notificationDisableMutation = useNotificationsDisableMutation(invalidate);
  const notificationDeleteMutation = useNotificationsDeleteMutation(invalidate);

  const handleSearchChange = (newValue: string) => {
    setQueryParams({ searchQuery: newValue });
  };

  const handleSelectedStatusesChange = (statuses: NotificationStatus[]) => {
    setQueryParams({ selectedNotificationStatuses: statuses });
  };

  const handleSortingTypeChange = (sortingType: SortingType) => {
    setQueryParams({ selectedSortingType: sortingType });
  };

  const query = useInfiniteNotificationsQuery({
    ...defaultPageParams,
    searchQuery: searchQuery,
    notificationsStatuses: selectedNotificationStatuses,
    sortingType: selectedSortingType,
  });
  const { isFetching } = query;

  const { ref, flatData } = useInfiniteScrolling(query, ({ items: notifications }) => notifications);

  const [selectedNotification, setSelectedNotification] = useState<SelectedNotification | null>(null);

  const columns = useNotificationsPageColumns({
    createdAtSortingControl: useSortingControl(selectedSortingType, handleSortingTypeChange),
  });
  const table = useReactTable<NotificationDto>({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    data: flatData,
    columns: columns,
  });

  const resetNotificationState = () => {
    setSelectedNotification(null);
  };
  return (
    <SelectedNotificationContext.Provider
      value={{
        selectedNotification,
        setSelectedNotification,
      }}
    >
      <Loading loading={isFetching} />
      <TopPanel
        searchValue={searchQuery}
        onSearchInputStop={handleSearchChange}
        placeholder="Search by notification text or ID"
        requestDelay={800}
        onSelectedStatusesChange={handleSelectedStatusesChange}
        selectedStatuses={selectedNotificationStatuses}
      />
      <AppModal open={selectedNotification !== null} onClose={resetNotificationState} preventClose={isFormSubmitting}>
        {selectedNotification?.state === 'Edit' && (
          <EditNotificationForm
            setIsFormSubmitting={setIsFormSubmitting}
            notificationData={selectedNotification.notification}
            onSubmitSuccessful={() => {
              invalidate();
              setIsFormSubmitting(false);
              resetNotificationState();
            }}
            onClose={resetNotificationState}
          />
        )}
        {selectedNotification?.state === 'ViewTarget' && (
          <NotificationTargetDisplay
            notification={selectedNotification.notification}
            onClose={resetNotificationState}
          />
        )}
        {selectedNotification?.state === 'Disable' && (
          <ConfirmationModal
            title="Disable notification?"
            description="This action cannot be revoked. Notification will no longer be shown to the users. 
            It will still remain in the notification list."
            onCancel={resetNotificationState}
            confirmButtonLabel="Disable notification"
            isLoading={notificationDisableMutation.isLoading}
            onConfirm={async () => {
              await notificationDisableMutation.mutateAsync(selectedNotification.notification.id);
              resetNotificationState();
            }}
          />
        )}
        {selectedNotification?.state === 'Delete' && (
          <ConfirmationModal
            title="Delete notification?"
            description="Notification will be permanently removed from the notification list. 
            It will no longer be shown to the users."
            onCancel={resetNotificationState}
            confirmButtonLabel="Delete notification"
            isLoading={notificationDeleteMutation.isLoading}
            onConfirm={async () => {
              await notificationDeleteMutation.mutateAsync(selectedNotification.notification.id);
              resetNotificationState();
            }}
          />
        )}
      </AppModal>
      <AppTable table={table} bottomRef={ref} estimateRowSize={() => 72} />
    </SelectedNotificationContext.Provider>
  );
};
