import { Button } from '@mui/material';
import React, { useState } from 'react';

import { DeviceFirmwareGeneration, GenerationsInfoMap, getFirmwareGenerationInfo } from 'api/firmware-api';
import { AppModal } from 'components/AppModal/AppModal';
import { MultiSelect } from 'components/MultiSelect/MultiSelect';
import { useInvalidateFirmwarePageQuery } from 'helpers/react-query/notifications-query-hooks';
import { UploadFileForm } from '../Forms/UploadFileForm/UploadFileForm';

import styles from './TopPanel.module.scss';

type Props = {
  allStages: string[];
  selectedStages: string[];
  onSelectedStagesChange: (stages: string[]) => void;
  selectedGenerations: DeviceFirmwareGeneration[];
  onSelectedGenerationsChange: (generation: DeviceFirmwareGeneration[]) => void;
};

export const TopPanel: React.FC<Props> = ({
  allStages,
  selectedStages,
  onSelectedStagesChange,
  selectedGenerations,
  onSelectedGenerationsChange,
}) => {
  const [isUploadFileFormOpen, setUploadFileFormOpen] = useState<boolean>(false);
  const invalidate = useInvalidateFirmwarePageQuery();
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  return (
    <div className={styles.topPanel}>
      <MultiSelect
        onChange={(val) => onSelectedGenerationsChange(val as DeviceFirmwareGeneration[])}
        options={GenerationsInfoMap.map((x) => x.firmwareGeneration)}
        placeholder={'All generations'}
        value={selectedGenerations}
        labelStrategy={(x) => getFirmwareGenerationInfo(x as DeviceFirmwareGeneration).name}
      />
      <MultiSelect
        onChange={onSelectedStagesChange}
        options={allStages}
        placeholder={'All stages'}
        value={selectedStages}
      />
      <Button
        disableRipple
        sx={{
          width: '150px',
          marginLeft: 'auto',
        }}
        onClick={() => setUploadFileFormOpen(true)}
        children={'Upload new FW'}
      />
      <AppModal
        open={isUploadFileFormOpen}
        onClose={() => setUploadFileFormOpen(false)}
        preventClose={isFormSubmitting}
        dialogPaperClassName={styles.uploadModalDialogPaper}
      >
        <UploadFileForm
          setFormLocked={setIsFormSubmitting}
          onSubmitSuccess={() => {
            invalidate();
            setUploadFileFormOpen(false);
          }}
          onClose={() => {
            setUploadFileFormOpen(false);
          }}
        />
      </AppModal>
    </div>
  );
};
